import axios from "axios";

// functions
import { getUnmaskedValue } from "../functions/maskString";

const saveLead = async (
  stateRef,
  dispatch
) => {
  //console.log('Save lead');

  const appdata = stateRef.current;
  const { initialServerData, formData } = appdata;
  const { ajax_url, nonce, post_id } = appdata?.backendSetup;

  if (!formData.email) {
    //console.log("No email added");
    return;
  }

  let save_lead_data = new FormData();
  save_lead_data.set("action", "ktk_donations_save_lead");
  save_lead_data.set("nonce", nonce);
  save_lead_data.set("form_id", post_id);
  save_lead_data.set("campaign_id", initialServerData.campaign_id);
  save_lead_data.set("cancelUrl", initialServerData.current_url);
  save_lead_data.set("returnUrl", initialServerData.thank_you_url);

  for ( var key in formData ) {
    save_lead_data.set(key, formData[key]);
  }

  if (formData?.lead_id) {
    save_lead_data.set("lead_id", formData.lead_id);
  }

  if (formData?.isMaskedPersonalDetails) {
    save_lead_data.set("firstname", getUnmaskedValue("firstname", formData));
    save_lead_data.set("lastname", getUnmaskedValue("lastname", formData));
    save_lead_data.set("address", getUnmaskedValue("address", formData));
    save_lead_data.set("zip", getUnmaskedValue("zip", formData));
    save_lead_data.set("city", getUnmaskedValue("city", formData));
  }

  try {
    const res = await axios.post(ajax_url, save_lead_data);
    if (res.data && res.data.id) {
      //console.log(res.data.id);

      // Update form data with the newly created lead id
      dispatch({
        type: "setFormValue",
        value: { 
          name: "lead_id", 
          value: res.data.id 
        },
      });

    } else {
      return;
    }
  } catch (e) {
    console.error(e);
  }
};

export {
  saveLead
};

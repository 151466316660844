export const getErrorMessage = (name) => {
  const errors = {
    email: "E-postadressen har ett felaktigt format.",
    socialsecuritynumber: "Vänligen ange ett giltigt personnummer (12 siffror).",
    recipient_email: "E-postadressen har ett felaktigt format.",
    clearing: "Använd 4 siffror. För Swedbankkonto, ta bort den femte siffran i clearingnumret.",
    account: "Använd mellan 6 och 11 siffror.",
    orgnumber: "Organisationsnumret har ett felaktigt format.",
    zip: "Postnumret har ett felaktigt format, vänligen ange 5 siffror.",
    recipient_zip: "Postnumret har ett felaktigt format.",
    phone: "Vänligen använd mellan 8 och 25 tecken.",
    amount: "Lägsta belopp är 50 kr.",
    burial_date: "Vänligen ange ett giltigt datum (åååå-mm-dd)."
  };

  if (errors[name]) {
    return errors[name];
  }
  
  return "Vänligen använd mellan 2 och 75 tecken.";
};

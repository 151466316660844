export const findGlobalContent = ({
  globalData,
  key,
  innerKey,
  defaultValue,
}) => {
  if (globalData?.[key]?.[innerKey]) {
    return globalData[key][innerKey];
  } else if (!innerKey && globalData?.[key]) {
    return globalData[key];
  } else if (defaultValue) {
    return defaultValue;
  } else {
    return "";
  }
};

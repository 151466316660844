export const Calendar = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.10714 4.375H14.8929V2.89062C14.8929 2.39893 15.3228 2 15.8571 2C16.3915 2 16.8214 2.39893 16.8214 2.89062V4.375H18.4286C19.8469 4.375 21 5.43818 21 6.75V18.625C21 19.935 19.8469 21 18.4286 21H5.57143C4.15112 21 3 19.935 3 18.625V6.75C3 5.43818 4.15112 4.375 5.57143 4.375H7.17857V2.89062C7.17857 2.39893 7.60848 2 8.14286 2C8.67723 2 9.10714 2.39893 9.10714 2.89062V4.375ZM4.92857 18.625C4.92857 18.9516 5.21625 19.2188 5.57143 19.2188H18.4286C18.7821 19.2188 19.0714 18.9516 19.0714 18.625V9.125H4.92857V18.625Z"
      fill="currentColor"
    />
  </svg>
);

export const formatMaskedString = (value, removeSpaces = false) => {
  if (!value) return value;

  // Remove spaces if param for this is true
  if ( removeSpaces ) {
    value = value.replace(/\s+/g, '');
  }

  const stringArray = value.split(/(\s+)/);

  const maskedStringArray = stringArray.map((str) => {
    // If string is shorter than 3 charachters, skip masking
    if (str.length < 3 ) {
      return str;
    }

    let maskedString = "";
    for (var i = 0; i < str.length; i++) {
      // If first or last charachter
      if (i === 0 || i === (str.length - 1) ) {
        maskedString += str[i];
      } else { // If any other charachters
        maskedString += "*";
      }
    }
    return maskedString;
  })

  return maskedStringArray.join('');
};

export const getUnmaskedValue = (inputName, data) => {
  const currentValue = data[inputName];
  const unmaskedValue = data[`unmasked_${inputName}`];

  // Check if masked value has been changed or is identical to inital masking
  if ( formatMaskedString(unmaskedValue) === currentValue ) {
    // If it is unchanged return unmaskedValue
    return unmaskedValue;
  } else if ( formatMaskedString(unmaskedValue, true) === currentValue ) {
    // Check if masked value is identical to inital masking if remove spaces was set to true
    return unmaskedValue;
  } else {
    // Else return current value as it must have been changed
    return currentValue;
  }
};
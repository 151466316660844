import { useContext, useEffect, useState, forwardRef } from "react";
import axios from "axios";

// functions
import { findGlobalContent } from "../../../../functions/findGlobalContent";
import { AppdataContext, AppdataDispatchContext } from "../../../../functions/appdataContext";

// components
import Button from "../../../Button/Button";
import Card from "../../../Card/Card";
import CertificatePreview from "./components/CertificatePreview";
import Icon from "../../../Icon/Icon";
import InputField from "../../../InputField/InputField";
import Modal from "../../../Modal/Modal";
import Radio from "../../../Radio/Radio";
import RadioGroup from "../../../RadioGroup/RadioGroup";

const CreateCertificateForm = forwardRef(
  (
    {
      globalData = {},
      donationType,
      handlePreviewButton,
      isOpenMemorialPreview,
      isOpenTributePreview,
      pdfArray,
      titlePrefixNumber,
      toggleOpenMemorialPreview,
      toggleOpenTributePreview,
      onChangeInput = () => {},
      onChangeInputRadioOrCheckbox = () => {},
      handleInputFocus = () => {},
      handleInputBlur = () => {},
    },
    ref
  ) => {
    const appdata = useContext(AppdataContext);
    const dispatch = useContext(AppdataDispatchContext);
    const { formData } = appdata;
    const [previewUrl, setPreviewUrl] = useState("");
    const [selectedCardInPdf, setSelectedCardInPdf] = useState("");
    const [textInPdf, setTextInPdf] = useState("");
    const [loading, setLoading] = useState(false);
    const selectedCardDefaultValue = pdfArray[0].name;
    const isMemorialDonation = donationType === "memorial";

    // global content keys
    const keys = {
      title: isMemorialDonation
        ? "title_createMemorial"
        : "title_createTribute",
      text: isMemorialDonation ? "text_createMemorial" : "text_createTribute",
      motif: "label_certificateMotif",
      greeting: isMemorialDonation
        ? "input_memorialGreeting"
        : "input_tributeGreeting",
      burialDate: "input_funeralDate",
      previewButton: isMemorialDonation
        ? "button_previewMemorial"
        : "button_previewTribute",
    };

    // default values - input field labels
    const labels = {
      greeting: "Din hälsning",
      burialDate: "Datum för begravning",
    };

    // default values - input field placeholders
    const placeholders = {
      greeting: isMemorialDonation
        ? "Skriv din hälsning och ditt/era namn här"
        : "Skriv din hälsning här",
    };

    // default values - other
    const texts = {
      title: isMemorialDonation ? "Skapa minnesblad" : "Skapa gåvokort",
      motif: "Motiv",
      previewButton: isMemorialDonation
        ? "Förhandsgranska minnesblad"
        : "Förhandsgranska gåvokort",
    };

    const motifProps = {
      globalData,
      key: keys.motif,
      defaultValue: texts.motif,
    };

    useEffect(() => {
      if (selectedCardDefaultValue) {
        dispatch({
          type: "setFormValue",
          value: { name: "selected_card", value: selectedCardDefaultValue },
        });
      }
    }, [selectedCardDefaultValue]);

    const handlePreview = (event) => {
      if (
        formData?.selected_card !== selectedCardInPdf ||
        formData?.card_text !== textInPdf
      ) {
        getPreview(event);
      } else {
        isMemorialDonation
          ? toggleOpenMemorialPreview()
          : toggleOpenTributePreview();
      }
    };

    const getPreview = (event) => {
      event.preventDefault();
      console.log("Get preview");
      setLoading(true);

      const { formData } = appdata;
      const { ajax_url, nonce } = appdata?.backendSetup;

      let get_preview_data = new FormData();
      get_preview_data.set("action", "ktk_donations_get_preview");
      get_preview_data.set("nonce", nonce);
      get_preview_data.set("card_text", formData.card_text);

      get_preview_data.set(
        "card",
        JSON.stringify(
          pdfArray.filter((element) => element.name === formData.selected_card)
        )
      );

      axios
        .post(ajax_url, get_preview_data)
        .then((res) => {
          //console.log(res);

          if (res.data) {
            setPreviewUrl(res.data.url);
            setSelectedCardInPdf(formData?.selected_card);
            setTextInPdf(formData?.card_text);
            setLoading(false);
            isMemorialDonation
              ? toggleOpenMemorialPreview()
              : toggleOpenTributePreview();
          }
        })
        .catch((err) => {
          //console.log(err);
          setLoading(false);
        });
    };

    return (
      <Card
        title={`${titlePrefixNumber}. ${findGlobalContent({
          globalData,
          key: keys.title,
          defaultValue: texts.title,
        })}`}
        text={findGlobalContent({ globalData, key: keys.text })}
      >
        <RadioGroup
          variant="image-selector"
          onValueChange={(value) =>
            onChangeInputRadioOrCheckbox("selected_card", value)
          }
          defaultValue={selectedCardDefaultValue}
          value={formData?.selected_card}
          label={findGlobalContent(motifProps)}
          name="certificate_image"
        >
          {pdfArray?.map((item, index) => (
            <Radio
              key={`card-selector-${item.name}`}
              id={`certificate-image-option-${item.name}`}
              value={item.name}
              variant="image-selector"
              imageProps={{
                src: item.preview?.sizes?.medium,
                width: item.preview?.sizes?.["medium-width"],
                height: item.preview?.sizes?.["medium-height"],
                alt: item.preview?.alt,
              }}
              label={`${findGlobalContent(motifProps)} ${index + 1}`}
            />
          ))}
        </RadioGroup>

        <InputField
          ref={ref}
          isTextArea
          id="card_text"
          name="card_text"
          value={formData?.card_text}
          globalKey={keys.greeting}
          defaultValues={{
            label: labels.greeting,
            placeholders: placeholders.greeting,
          }}
          onChange={onChangeInput}
          onFocus={(event) => handleInputFocus(event)}
          onBlur={(event) => handleInputBlur(event)}
        />

        {isMemorialDonation && (
          <InputField
            id="burial_date"
            name="burial_date"
            type="date"
            value={formData?.burial_date}
            globalKey={keys.burialDate}
            defaultValues={{
              label: labels.burialDate,
            }}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            placeholder="åååå-mm-dd"
          />
        )}

        <Modal
          isActive={
            isMemorialDonation ? isOpenMemorialPreview : isOpenTributePreview
          }
          closeModal={
            isMemorialDonation
              ? toggleOpenMemorialPreview
              : toggleOpenTributePreview
          }
          trigger={
            loading ? (
              <Button disabled>
                <Icon variant="loader" size="medium" />
              </Button>
            ) : (
              <Button
                onClick={handlePreview}
                disabled={!formData?.card_text}
                id="preview-certificate-button"
                name="preview-certificate-button"
              >
                {findGlobalContent({
                  globalData,
                  key: keys.previewButton,
                  defaultValue: texts.previewButton,
                })}
              </Button>
            )
          }
        >
          <CertificatePreview
            isMemorialDonation={isMemorialDonation}
            globalData={globalData}
            previewUrl={previewUrl}
            handlePreviewButton={handlePreviewButton}
          />
        </Modal>
      </Card>
    );
  }
);

export default CreateCertificateForm;

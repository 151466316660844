import { useContext, useState } from "react";
import axios from "axios";

// hooks
import { useValidateFetchedAddressFields } from "../../../../hooks/useFetchedFields";

// functions
import {
  AppdataContext,
  AppdataDispatchContext,
} from "../../../../functions/appdataContext";
import { findGlobalContent } from "../../../../functions/findGlobalContent";
import { formatMaskedString } from "../../../../functions/maskString";

// components
import InputField from "../../../InputField/InputField";
import Button from "../../../Button/Button";
import Card from "../../../Card/Card";
import Typography from "../../../Typography/Typography";
import Icon from "../../../Icon/Icon";

// styles
import styles from "./PersonalDetailsForm.module.scss";

const PersonalDetailsForm = ({
  globalData = {},
  manualPersonalDetailsFormIsOpen,
  setManualPersonalDetailsFormIsOpen,
  titlePrefixNumber,
  triggerSaveLead,
  onChangeInput = () => {},
  handleInputFocus = () => {},
  handleInputBlur = () => {},
  clearFormResponse = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const { formData } = appdata;
  const [addressIsFetched, setAdressIsFetched] = useState(false);
  const [errorOnGetAddress, setErrorOnGetAddress] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const correctSsnLength = formData?.socialsecuritynumber?.length === 12;
  const isMonthlyDonation = formData?.donation_type === "monthly";

  // global content keys
  const keys = {
    title: "title_personalDetails",
    textPersonalDetails: "text_personalDetails",
    inputEmail: "input_email",
    inputPhone: "input_phone",
    inputSsn: "input_ssn",
    buttonManualDetails: "button_manualContactDetails",
    inputFirstname: "input_firstname",
    inputLastname: "input_lastname",
    inputAddress: "input_address",
    inputZip: "input_zip",
    inputCity: "input_city",
  };

  // default values - input field labels
  const labels = {
    inputEmail: "Din e-post",
    inputPhone: "Ditt telefonnummer",
    inputSsn: "Personnummer",
    inputFirstname: "Förnamn",
    inputLastname: "Efternamn",
    inputAddress: "Adress",
    inputZip: "Postnummer",
    inputCity: "Ort",
  };

  // default values - input field placeholders
  const placeholders = {
    inputSsn: "ååååmmddxxxx",
  };

  // default values - other
  const texts = {
    title: "Dina personuppgifter",
    textPersonalDetails:
      "När du går vidare sparar vi dina uppgifter så vi kan skicka dig en bekräftelse.",
    buttonManualDetails: "Redigera adress",
  };

  const clearAdressFields = () => {
    const fields = [
      "firstname",
      "lastname",
      "address",
      "zip",
      "city",
      "unmasked_firstname",
      "unmasked_lastname",
      "unmasked_address",
      "unmasked_zip",
      "unmasked_city",
    ];

    fields.forEach((item) => {
      onChangeInput({ target: { name: item, value: "" } });
      clearFormResponse(item);
    });
  };

  // WP: Get adress (Billecta)
  const getAddress = async (event) => {
    event.preventDefault();
    setErrorOnGetAddress(null);
    setManualPersonalDetailsFormIsOpen(false);
    setLoadingAddress(true);

    if (addressIsFetched) {
      clearAdressFields();
    }

    setAdressIsFetched(false);

    const { formData } = appdata;
    const { ajax_url, nonce } = appdata?.backendSetup;
    const ssn = formData?.socialsecuritynumber;

    if (!ssn) return;

    let get_address_data = new FormData();
    get_address_data.set("action", "ktk_donations_get_address");
    get_address_data.set("nonce", nonce);
    get_address_data.set("ssn", ssn);

    try {
      const res = await axios.post(ajax_url, get_address_data);

      if (res.data && res.data.FirstName) {
        dispatch({
          type: "setFormData",
          value: {
            ...formData,
            firstname: formatMaskedString(res.data.FirstName),
            lastname: formatMaskedString(res.data.LastName),
            address: formatMaskedString(res.data.Address),
            zip: formatMaskedString(res.data.Zipcode, true),
            city: formatMaskedString(res.data.City),
            unmasked_firstname: res.data.FirstName,
            unmasked_lastname: res.data.LastName,
            unmasked_address: res.data.Address,
            unmasked_zip: res.data.Zipcode,
            unmasked_city: res.data.City,
            isMaskedPersonalDetails: true,
            usedAddressLookup: true,
          },
        });
        setAdressIsFetched(true);

        // TODO - create better method for assuring data has been saved before triggering saveLead
        setTimeout(() => {
          triggerSaveLead();
        }, 500);
      } else {
        console.error("Something went wrong");
        setErrorOnGetAddress(true);
      }
    } catch (e) {
      console.error(e);
      setErrorOnGetAddress(true);
    }

    setLoadingAddress(false);
  };

  useValidateFetchedAddressFields(addressIsFetched);

  const handleSsnInputFocus = (event) => {
    setErrorOnGetAddress(false);
    handleInputFocus(event);
  };

  const handleSsnInputBlur = (event) => {
    if (formData?.socialsecuritynumber?.length === 12) getAddress(event);
    handleInputBlur(event);
  };

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.title,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({
        globalData,
        key: keys.textPersonalDetails,
        defaultValue: texts.textPersonalDetails,
      })}
    >
      <InputField
        name="email"
        id="email-input"
        value={formData.email}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.inputEmail}
        defaultValues={{
          label: labels.inputEmail,
        }}
      />

      <InputField
        type="tel"
        name="phone"
        id="phone-input"
        value={formData.phone}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.inputPhone}
        defaultValues={{
          label: labels.inputPhone,
        }}
      />

      <InputField
        type="tel"
        name="socialsecuritynumber"
        id="socialsecuritynumber-input"
        value={formData?.socialsecuritynumber}
        onChange={onChangeInput}
        onFocus={(event) => handleSsnInputFocus(event)}
        onBlur={(event) => handleSsnInputBlur(event)}
        globalKey={keys.inputSsn}
        defaultValues={{
          label: labels.inputSsn,
          placeholder: placeholders.inputSsn,
        }}
      />

      {!addressIsFetched && !errorOnGetAddress && !loadingAddress && (
        <Typography variant="button-hint-text">
          {isMonthlyDonation
            ? "Fyll i ditt 12-siffriga personnummer — krävs för att bli månadsgivare via autogiro och hämta din adress."
            : "Fyll i ditt 12-siffriga personnummer — krävs för att hämta din adress."}
        </Typography>
      )}

      {loadingAddress && (
        <div className={styles["loading-address"]}>
          <Icon variant="loader" />
          <Typography>Hämtar address...</Typography>
        </div>
      )}

      {addressIsFetched &&
        !manualPersonalDetailsFormIsOpen &&
        !errorOnGetAddress && (
          <div className={styles["address-summary"]}>
            <Typography variant="headline-3">
              Hämtade adressuppgifter
            </Typography>

            <div className={styles["address"]}>
              <Typography variant="label">
                {formData?.firstname} {formData?.lastname}
              </Typography>
              <Typography variant="label">{formData?.address}</Typography>
              <Typography variant="label">
                {formData?.zip} {formData?.city}
              </Typography>
            </div>

            <Button
              variant="tertiary"
              onClick={() => setManualPersonalDetailsFormIsOpen(true)}
              id="open-manual-address-form-button"
            >
              Redigera adress
            </Button>
          </div>
        )}

      {errorOnGetAddress && correctSsnLength && (
        <Typography variant="button-hint-text">
          Kunde inte hämta address. Korrigera det angivna personnumret eller
          fyll i adressuppgifterna manuellt.
        </Typography>
      )}

      {manualPersonalDetailsFormIsOpen && (
        <>
          <InputField
            name="firstname"
            id="firstname-input"
            value={formData.firstname}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputFirstname}
            defaultValues={{
              label: labels.inputFirstname,
            }}
          />

          <InputField
            name="lastname"
            id="lastname-input"
            value={formData.lastname}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputLastname}
            defaultValues={{
              label: labels.inputLastname,
            }}
          />

          <InputField
            name="address"
            id="address-input"
            value={formData.address}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputAddress}
            defaultValues={{
              label: labels.inputAddress,
            }}
          />

          <InputField
            name="zip"
            id="zip-code-input"
            value={formData.zip}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputZip}
            defaultValues={{
              label: labels.inputZip,
            }}
          />

          <InputField
            name="city"
            id="city-input"
            value={formData.city}
            onChange={onChangeInput}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleInputBlur(event)}
            globalKey={keys.inputCity}
            defaultValues={{
              label: labels.inputCity,
            }}
          />
        </>
      )}

      {errorOnGetAddress && !manualPersonalDetailsFormIsOpen && (
        <Button
          variant="tertiary"
          onClick={() => setManualPersonalDetailsFormIsOpen(true)}
          id="open-manual-address-form-button"
        >
          {findGlobalContent({
            globalData,
            key: keys.buttonManualDetails,
            defaultValue: texts.buttonManualDetails,
          })}
        </Button>
      )}

      {manualPersonalDetailsFormIsOpen && (
        <Button
          variant="tertiary"
          onClick={() => setManualPersonalDetailsFormIsOpen(false)}
          id="close-manual-address-form-button"
        >
          Göm adressfält
        </Button>
      )}
    </Card>
  );
};

export default PersonalDetailsForm;

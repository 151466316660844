import parse from "html-react-parser";
import clsx from "clsx";

// styles
import styles from "./Infobox.module.scss";

const Infobox = ({ className, image, content }) => (
  <div
    className={clsx(
      styles["infobox"],
      !image?.url && styles["content-only"],
      className
    )}
  >
    {image?.url && (
      <div className={styles["image-wrapper"]}>
        <img
          className={styles["image"]}
          src={image?.url}
          width={image?.width}
          height={image?.height}
          alt={image?.alt}
        />
      </div>
    )}

    <div className={styles["richtext"]}>{parse(content)}</div>
  </div>
);

export default Infobox;

import clsx from "clsx";

// styles
import styles from "./Typography.module.scss";

const Typography = ({
  variant = "information",
  color = "black",
  children,
  className,
}) => {
  const Element =
    variant === "headline-2" ? "h2" : variant === "headline-3" ? "h3" : "p";

  return (
    <Element
      className={clsx(
        styles["typography"],
        styles[variant],
        styles[color],
        className
      )}
    >
      {children}
    </Element>
  );
};
export default Typography;

import { forwardRef, useContext } from "react";
import clsx from "clsx";

// styles
import styles from "./InputField.module.scss";

// functions
import { findGlobalContent } from "../../functions/findGlobalContent";
import { AppdataContext } from "../../functions/appdataContext";

// components
import Icon from "../Icon/Icon";
import Typography from "../Typography/Typography";

const InputField = forwardRef(
  (
    {
      type = "text",
      name,
      id,
      globalKey = "",
      defaultValues = {},
      value,
      isTextArea = false,
      ...props
    },
    ref
  ) => {
    const appdata = useContext(AppdataContext);
    const { initialServerData, formErrors, formSuccess } = appdata;
    const { global: globalData } = initialServerData || {};
    const Element = isTextArea ? "textarea" : "input";
    const error = formErrors[name]
    const success = formSuccess[name]
    const hasSuffix = error || type === "date" || type === "number" || success;

    const label = findGlobalContent({
      globalData,
      key: globalKey,
      innerKey: "label",
      defaultValue: defaultValues.label || "",
    });

    const hint = findGlobalContent({
      globalData,
      key: globalKey,
      innerKey: "hint",
      defaultValue: defaultValues.hint || "",
    });

    const placeholder = findGlobalContent({
      globalData,
      key: globalKey,
      innerKey: "placeholder",
      defaultValue: defaultValues.placeholder || "",
    });

    return (
      <div className={clsx(styles["input-field"], props.className)}>
        <div className={styles["label-hint-wrapper"]}>
          {label && (
            <label htmlFor={id}>
              <Typography variant="input-label">{label}</Typography>
            </label>
          )}
          {hint && (
            <Typography variant="information" color="black-75">
              {hint}
            </Typography>
          )}
        </div>

        <div className={styles["input-wrapper"]}>
          <Element
            ref={ref}
            aria-hidden={type === "date"}
            className={clsx(
              styles["input"],
              error && styles["error"],
              success && styles["success"],
              Element === "textarea" && styles["textarea"],
              type === "date" && styles["date"]
            )}
            type={type === "date" ? "text" : type}
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            {...props}
          />

          {type === "date" && (
            <input
              type="date"
              className={clsx(styles["calendar-picker-input"])}
              name={name}
              id={id}
              value={value}
              {...props}
            />
          )}

          {hasSuffix && (
            <div
              className={clsx(
                styles["suffix-container"],
                error && styles["error"],
                success && styles["success"]
              )}
            >
              {error ? (
                <Icon variant="info-triangle" size="small" />
              ) : success ?
                <Icon variant="checkmark-round-success" size="small" />
              : name === "burial_date" ? (
                <Icon variant="calendar" size="medium" />
              ) : type === "number" ? (
                <Typography color="black-50">kr</Typography>
              ) : (
                ""
              )}
            </div>
          )}
        </div>

        {error && <div className={styles["error-message"]}>{error}</div>}
      </div>
    );
  }
);

export default InputField;

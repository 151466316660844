export const ChevronDown = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.773 8.46967C19.0659 8.76256 19.0659 9.23744 18.773 9.53033L12.6517 15.6517C12.3588 15.9445 11.8839 15.9445 11.591 15.6517L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967C5.76256 8.17678 6.23744 8.17678 6.53033 8.46967L12.1213 14.0607L17.7123 8.46967C18.0052 8.17678 18.4801 8.17678 18.773 8.46967Z"
      fill="currentColor"
    />
  </svg>
);

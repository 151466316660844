import { useContext } from "react";

// functions
import { AppdataContext } from "../../../functions/appdataContext";
import { findGlobalContent } from "../../../functions/findGlobalContent";

// components
import Card from "../../Card/Card";
import RadioGroup from "../../RadioGroup/RadioGroup";
import Radio from "../../Radio/Radio";

const SinglePaymentForm = ({
  globalData = {},
  titlePrefixNumber,
  donationType,
  onChangeInputRadioOrCheckbox = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;
  const radioVariant = "button-selector";

  // global content keys
  const keys = {
    title: "title_paymentType",
    text: "text_paymentType",
    radioCreditcard: "radio_creditcard",
    radioSwish: "radio_swish",
    radioInvoice: "radio_invoice",
  };

  // default values
  const texts = {
    title: "Välj betalsätt",
    radioCreditcard: "Bankkort",
    radioSwish: "Swish",
    radioInvoice: "Faktura",
  };

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.title,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({ globalData, key: keys.text })}
    >
      <RadioGroup
        onValueChange={(value) => onChangeInputRadioOrCheckbox("method", value)}
        value={formData?.method}
        variant={donationType === "company" ? "default" : "button-selector"}
        name="method"
      >
        <Radio
          id="payment-method-creditcard"
          value="creditcard"
          name="creditcard"
          label={findGlobalContent({
            globalData,
            key: keys.radioCreditcard,
            defaultValue: texts.radioCreditcard,
          })}
          variant={radioVariant}
        />

        <Radio
          id="payment-method-swish"
          value="swish"
          name="swish"
          label={findGlobalContent({
            globalData,
            key: keys.radioSwish,
            defaultValue: texts.radioSwish,
          })}
          variant={radioVariant}
        />

        {donationType === "company" && (
          <Radio
            id="payment-method-invoice"
            value="invoice"
            name="invoice"
            label={findGlobalContent({
              globalData,
              key: keys.radioInvoice,
              defaultValue: texts.radioInvoice,
            })}
            variant={radioVariant}
          />
        )}
      </RadioGroup>
    </Card>
  );
};

export default SinglePaymentForm;

import { useContext } from "react";

// functions
import { AppdataContext } from "../../../../functions/appdataContext";
import { findGlobalContent } from "../../../../functions/findGlobalContent";

// components
import Card from "../../../Card/Card";
import InputField from "../../../InputField/InputField";
import Typography from "../../../Typography/Typography";
import MethodPostForm from "./components/MethodPostForm";
import RadioGroup from "../../../RadioGroup/RadioGroup";
import Radio from "../../../Radio/Radio";

const CertificateDeliveryForm = ({
  globalData,
  donationType,
  titlePrefixNumber,
  onChangeInput = () => {},
  onChangeInputRadioOrCheckbox = () => {},
  handleInputFocus = () => {},
  handleInputBlur = () => {},
  handleValidation = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;

  // global content keys
  const keys = {
    title: "title_delivery",
    text: "text_delivery",
    label:
      donationType === "memorial"
        ? "label_sendMemorialCertificate"
        : "label_sendTributeCertificate",
    radioDigital: "radio_deliveryDigital",
    radioPost: "radio_deliveryPost",
    inputEmail:
      formData?.delivery_recipient === "relation"
        ? "input_emailRelation"
        : "input_emailOther",
    labelSendTo: "label_sendTo",
    radioUndertaker: "radio_undertakerOrChurch",
    radioRelation: "radio_relation",
    radioOther: "radio_other",
    radioSelf: "radio_self",
    textDeliverySelf:
      donationType === "memorial"
        ? "text_deliverySelfMemorial"
        : "text_deliverySelfTribute",
  };

  // default values - input field labels
  const labels = {
    inputEmail:
      formData?.delivery_recipient === "relation"
        ? "Anhörigs e-post"
        : "Mottagarens e-post",
  };

  // default values - other
  const texts = {
    title: "Leverans",
    label: donationType === "memorial" ? "Skicka minnesblad" : "Skicka gåvokort",
    radioDigital: "Digital med e-post",
    radioPost: "Fysiskt med post (25 kr för porto tillkommer)",
    labelSendTo: "Skicka till",
    radioUndertaker: "Begravningsbyrå eller kyrka",
    radioRelation: "Anhörig",
    radioOther: "Mottagaren",
    radioSelf: "Mig",
    textDeliverySelf:
      donationType === "memorial"
        ? "Minnesbladet skickas till samma adress angivet i dina personuppgifter."
        : "Gåvokortet skickas till samma adress angivet i dina personuppgifter.",
  };

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.title,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({ globalData, key: keys.text })}
    >
      <div style={{ maxWidth: "fit-content" }}>
        <div>
          <Typography variant="label">
            {findGlobalContent({
              globalData,
              key: keys.label,
              defaultValue: texts.label,
            })}
          </Typography>

          <RadioGroup
            onValueChange={(value) =>
              onChangeInputRadioOrCheckbox("delivery_method", value)
            }
            name="delivery_method"
            value={formData?.delivery_method}
          >
            <Radio
              id="delivery_option_email"
              value="email"
              name="delivery-method"
              label={findGlobalContent({
                globalData,
                key: keys.radioDigital,
                defaultValue: texts.radioDigital,
              })}
            />

            <Radio
              id="delivery_option_post"
              value="post"
              name="delivery-method"
              label={findGlobalContent({
                globalData,
                key: keys.radioPost,
                defaultValue: texts.radioPost,
              })}
            />
          </RadioGroup>
        </div>

        {formData?.delivery_method && (
          <div>
            <Typography variant="label">
              {findGlobalContent({
                globalData,
                key: keys.labelSendTo,
                defaultValue: texts.labelSendTo,
              })}
            </Typography>

            <RadioGroup
              onValueChange={(value) =>
                onChangeInputRadioOrCheckbox("delivery_recipient", value)
              }
              value={formData?.delivery_recipient}
              name="delivery_recipient"
            >
              {donationType === "memorial" && (
                <>
                  {formData.delivery_method !== "email" && (
                    <Radio
                      id="recipient_option_undertaker"
                      value="undertaker"
                      name="recipient"
                      label={findGlobalContent({
                        globalData,
                        key: keys.radioUndertaker,
                        defaultValue: texts.radioUndertaker,
                      })}
                    />
                  )}

                  <Radio
                    id="recipient_option_relation"
                    value="relation"
                    name="recipient"
                    label={findGlobalContent({
                      globalData,
                      key: keys.radioRelation,
                      defaultValue: texts.radioRelation,
                    })}
                  />
                </>
              )}

              {donationType === "tribute" && (
                <Radio
                  id="recipient__option_relation"
                  value="other"
                  name="recipient"
                  label={findGlobalContent({
                    globalData,
                    key: keys.radioOther,
                    defaultValue: texts.radioOther,
                  })}
                />
              )}

              <Radio
                id="recipient_option_self"
                value="self"
                label={findGlobalContent({
                  globalData,
                  key: keys.radioSelf,
                  defaultValue: texts.radioSelf,
                })}
              />
            </RadioGroup>
          </div>
        )}
      </div>

      {formData?.delivery_method === "post" && (
        <>
          {Boolean(
            formData.delivery_recipient &&
              formData.delivery_recipient !== "self"
          ) && (
            <MethodPostForm
              globalData={globalData}
              formData={formData}
              onChangeInput={onChangeInput}
              handleInputFocus={handleInputFocus}
              handleValidation={handleValidation}
            />
          )}
        </>
      )}

      {Boolean(
        formData.delivery_method === "email" &&
          formData.delivery_recipient &&
          formData.delivery_recipient !== "self"
      ) && (
        <InputField
          type="email"
          name="recipient_email"
          id="recipient_email"
          value={formData.recipient_email}
          onChange={onChangeInput}
          onFocus={(event) => handleInputFocus(event)}
          onBlur={(event) => handleInputBlur(event)}
          globalKey={keys.inputEmail}
          defaultValues={{
            label: labels.inputEmail,
          }}
        />
      )}

      {formData.delivery_recipient === "self" && (
        <Typography>
          {findGlobalContent({
            globalData,
            key: keys.textDeliverySelf,
            innerKey: "label",
            defaultValue: texts.textDeliverySelf,
          })}
        </Typography>
      )}
    </Card>
  );
};

export default CertificateDeliveryForm;

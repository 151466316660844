import { useContext, useEffect } from "react";
import { AppdataContext, AppdataDispatchContext } from "../functions/appdataContext";

export const useFormDonationType = () => {
  const dispatch = useContext(AppdataDispatchContext);
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;
  const donationType = formData?.donation_type || "";

  useEffect(() => {
    if (donationType === "monthly") {
      dispatch({
        type: "setFormData",
        value: {
          ...formData,
          bank: "",
          account: "",
          clearing: "",
          method: "autogiro",
          autogiro_optIn: false,
        },
      });
    } else if (donationType === "donation") {
      const { bank, account, clearing, autogiro_optIn, ...rest } = formData;

      dispatch({
        type: "setFormData",
        value: {
          ...rest,
          method: "",
        },
      });
    }
  }, [donationType]);
};

export const useFormDelivery = () => {
  const dispatch = useContext(AppdataDispatchContext);
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;
  const deliveryMethod = formData?.delivery_method || "";
  const deliveryRecipient = formData?.delivery_recipient || "";

  useEffect(() => {
    if (deliveryRecipient === "self") {
      const {
        recipient_firstname,
        recipient_lastname,
        recipient_address,
        recipient_zip,
        recipient_city,
        recipient_email,
        ...rest
      } = formData;

      dispatch({
        type: "setFormData",
        value: {
          ...rest,
        },
      });
    } else if (deliveryMethod === "email") {
      const {
        recipient_firstname,
        recipient_lastname,
        recipient_address,
        recipient_zip,
        recipient_city,
        ...rest
      } = formData;

      dispatch({
        type: "setFormData",
        value: {
          ...rest,
          recipient_email: "",
        },
      });
    } else if (deliveryMethod === "post") {

      if (deliveryRecipient === "undertaker") {
        const { recipient_lastname, recipient_email, ...rest } = formData;

        dispatch({
          type: "setFormData",
          value: {
            ...rest,
            recipient_firstname: "",
            recipient_address: "",
            recipient_zip: "",
            recipient_city: "",
          },
        });
      } else {
        const { recipient_email, ...rest } = formData;

        dispatch({
          type: "setFormData",
          value: {
            ...rest,
            recipient_firstname: "",
            recipient_lastname: "",
            recipient_address: "",
            recipient_zip: "",
            recipient_city: "",
          },
        });
      }
    }
  }, [deliveryMethod, deliveryRecipient]);
};

export const getFormState = (donationType) => {
  const defaultKeys = {
    email: "",
    phone: "",
    firstname: "",
    lastname: "",
    address: "",
    zip: "",
    city: "",
    method: "",
    amount: "",
    socialsecuritynumber: "",
    donation_type: donationType,
  };

  if (donationType === "memorial") {
    return {
      ...defaultKeys,
      selected_card: "",
      card_text: "",
      delivery_method: "",
      delivery_recipient: "",
      burial_date: "",
    };
  } else if (donationType === "tribute") {
    return {
      ...defaultKeys,
      selected_card: "",
      card_text: "",
      delivery_method: "",
      delivery_recipient: "",
    };
  } else if (donationType === "company") {
    const { socialsecuritynumber, ...rest } = defaultKeys;

    return {
      ...rest,
      companyname: "",
      orgnumber: "",
    };
  } else if (donationType === "monthly") {
    return {
      ...defaultKeys,
      bank: "",
      account: "",
      clearing: "",
      method: "autogiro",
      autogiro_optIn: false,
    };
  } else {
    return {
      ...defaultKeys,
    };
  }
};

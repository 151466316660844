import clsx from "clsx";
import { useContext, useEffect } from "react";

// functions
import { AppdataContext, AppdataDispatchContext } from "../../functions/appdataContext";

// components
import Icon from "../Icon/Icon";
import Typography from "../Typography/Typography";

// styles
import styles from "./Select.module.scss";

const Select = ({ children, className, onChange, id, name, label }) => {
  const appdata = useContext(AppdataContext);
  const dispatch = useContext(AppdataDispatchContext);
  const { formData, formErrors } = appdata;
  const error = formErrors[name];
  const success = formData[name];

  useEffect(() => {
    if (formData[name]) {
      dispatch({
        type: "setFormErrorValue",
        value: { name, value: "" },
      });
    }
  }, [formData[name]])

  return (
    <div className={clsx(styles["select-wrapper"], className)}>
      {label && (
        <label htmlFor={id}>
          <Typography variant="input-label">{label}</Typography>
        </label>
      )}

      <select
        className={clsx(styles["select"], success && styles["success"])}
        onChange={onChange}
        id={id}
        name={name}
      >
        {children}
      </select>

      <div
        className={clsx(
          styles["suffix-container"],
          !label && styles["no-label"]
        )}
      >
        <Icon variant="chevron-down" size="medium" />
      </div>

      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

export default Select;

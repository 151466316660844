import clsx from "clsx";
import parse from "html-react-parser";

// styles
import styles from "./Alert.module.scss";

// components
import Icon from "../Icon/Icon";

const Alert = ({ text, variant = "blue-alert" }) => {
  if (!text) return null;

  return (
    <div className={clsx(styles["alert"], styles[variant])}>
      <Icon variant="info-triangle" className={styles["icon"]} />
      <div className={styles["richtext"]}>{parse(text)}</div>
    </div>
  );
};

export default Alert;

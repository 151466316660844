export const BankId = (className) => (
  <svg 
    className={className}
    width="102" 
    height="95" 
    viewBox="0 0 102 95" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M32.5445 49.2316L36.7228 22.9579C35.0768 22.9579 32.2596 22.9579 32.2596 22.9579C30.1705 22.9579 27.4799 21.7895 26.6885 19.6421C26.4353 18.9158 25.8339 16.4211 29.2842 13.9895C30.5186 13.1368 31.31 12.1895 31.4683 11.4632C31.6265 10.7053 31.4366 10.0421 30.8985 9.53684C30.1388 8.81052 28.6511 8.4 26.7519 8.4C23.5548 8.4 21.3074 10.2316 21.0858 11.5579C20.9276 12.5368 21.6873 13.3263 22.352 13.8316C24.3462 15.3158 24.821 17.4632 23.5865 19.4842C22.3203 21.5684 19.5665 22.9263 16.6227 22.9579C16.6227 22.9579 13.7105 22.9579 12.0645 22.9579C11.6847 25.5158 5.48053 64.7368 5.00573 67.8316H29.6323C29.8539 66.4737 30.9935 59.0526 32.5445 49.2316Z" fill="currentColor"/>
    <path d="M3.67627 76.9263H13.7738C18.0787 76.9263 19.1233 79.1053 18.8068 81.0947C18.5535 82.7053 17.4457 83.9053 15.5464 84.6947C17.9521 85.6105 18.9017 87.0316 18.5535 89.2737C18.1104 92.0842 15.673 94.1684 12.476 94.1684H0.985703L3.67627 76.9263ZM10.3552 84.0632C12.3177 84.0632 13.2357 83.0211 13.4256 81.7895C13.6155 80.4632 13.0141 79.5474 11.0516 79.5474H9.31063L8.61425 84.0632H10.3552ZM9.27898 91.4842C11.3048 91.4842 12.476 90.6632 12.7609 88.9895C12.9825 87.5368 12.1595 86.6842 10.1969 86.6842H8.23441L7.47472 91.5158H9.27898V91.4842Z" fill="currentColor"/>
    <path d="M32.7028 94.2947C30.0755 94.4842 28.8093 94.2 28.1763 93.0632C26.7835 93.9158 25.2325 94.3579 23.5865 94.3579C20.611 94.3579 19.5665 92.8105 19.8513 91.1053C19.9779 90.2842 20.4528 89.4947 21.2124 88.8316C22.8584 87.4105 26.9101 87.221 28.4928 86.1474C28.6194 84.9474 28.1446 84.5053 26.6569 84.5053C24.9159 84.5053 23.4599 85.0737 20.9592 86.7789L21.5606 82.8632C23.7131 81.3158 25.8022 80.5895 28.2079 80.5895C31.2783 80.5895 34.0006 81.8526 33.4941 85.2L32.8927 88.9895C32.6711 90.3158 32.7344 90.7263 34.2221 90.7579L32.7028 94.2947ZM28.1446 88.3263C26.7519 89.2105 24.1562 89.0526 23.8714 90.8842C23.7447 91.7368 24.2829 92.3684 25.1375 92.3684C25.9605 92.3684 26.9734 92.0211 27.7964 91.4526C27.7331 91.1368 27.7648 90.8211 27.8597 90.2211L28.1446 88.3263Z" fill="currentColor"/>
    <path d="M37.6091 80.8105H42.8636L42.5787 82.5474C44.2564 81.1263 45.5225 80.5895 47.1685 80.5895C50.1123 80.5895 51.4734 82.3895 50.9986 85.3263L49.6375 94.1368H44.383L45.5225 86.8421C45.7441 85.5158 45.3326 84.8842 44.3197 84.8842C43.4967 84.8842 42.737 85.3263 42.009 86.3053L40.8061 94.1053H35.5516L37.6091 80.8105Z" fill="currentColor"/>
    <path d="M55.1136 76.9263H60.3681L59.0387 85.3895L64.0716 80.8105H70.5606L64.1033 86.4947L69.2945 94.1368H62.6789L58.6905 87.9474H58.6272L57.6776 94.1053H52.423L55.1136 76.9263Z" fill="currentColor"/>
    <path d="M73.6944 76.9263H79.7402L77.0813 94.1368H71.0354L73.6944 76.9263Z" fill="currentColor"/>
    <path d="M82.684 76.9263H91.3255C98.0044 76.9263 99.9353 81.7579 99.3022 85.7684C98.7008 89.6842 95.5987 94.1368 89.7428 94.1368H79.9935L82.684 76.9263ZM88.2867 90.0316C91.2305 90.0316 92.8449 88.5789 93.3197 85.5158C93.6679 83.2421 92.9715 81 89.7111 81H88.0968L86.704 90.0316H88.2867Z" fill="currentColor"/>
    <path d="M65.6543 0H40.4896L37.1343 21.2526H41.4075C43.7499 21.2526 45.9657 20.1789 46.9153 18.6316C47.2318 18.1263 47.3584 17.6842 47.3584 17.2737C47.3584 16.3895 46.757 15.7263 46.1556 15.2842C44.5096 14.0526 44.1614 12.7579 44.1614 11.8421C44.1614 11.6526 44.1614 11.4947 44.1931 11.3368C44.5413 9.09473 47.58 6.66316 51.6 6.66316C54.0057 6.66316 55.8416 7.23158 56.9495 8.27368C57.9308 9.18947 58.3106 10.4842 58.0257 11.8421C57.6776 13.4526 56.0632 14.7789 55.1453 15.4421C52.7079 17.1474 53.0245 18.6316 53.1827 19.0737C53.6892 20.4 55.6201 21.2526 57.1078 21.2526H63.6285C63.6285 21.2526 63.6285 21.2526 63.6285 21.2842C72.4915 21.3474 77.2396 25.4211 75.7518 34.8947C74.3591 43.7053 67.5852 47.4947 59.5135 47.5579L56.3164 67.8947H61.0329C80.9431 67.8947 97.2131 55.1368 100.41 34.9895C104.367 9.97895 88.445 0 65.6543 0Z" fill="currentColor"/>
  </svg>  
);
export const formatSsn = (value) => {
  const ssn = value?.replace(/[^\d]/g, "");
  const ssnLength = ssn.length;

  if (!value) return value;

  if (ssnLength <= 8) return ssn;

  return `${ssn.slice(0, 8)}-${ssn.slice(8, 12)}`;
};

export const formatNumberString = (value) => {
  const numberString = value?.replace(/[^\d]/g, "");

  if (!value) return value;

  return numberString;
};

export const formatDate = (value) => {
  const date = value?.replace(/[^\d]/g, "");
  const dateLength = date.length;

  if (!value) return value;

  if (dateLength <= 4) return date;

  if (dateLength <= 6) return `${date.slice(0, 4)}-${date.slice(4, 6)}`;

  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}`;
};

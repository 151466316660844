import { forwardRef } from "react";

// functions
import { findGlobalContent } from "../../../functions/findGlobalContent";

// components
import Card from "../../Card/Card";
import InputField from "../../InputField/InputField";
import RadioGroup from "../../RadioGroup/RadioGroup";
import Radio from "../../Radio/Radio";

const DonationAmountForm = forwardRef(
  (
    {
      globalData = {},
      onChangeInput = () => {},
      onChangeInputRadioOrCheckbox = () => {},
      handleInputFocus = () => {},
      amount,
      amounts,
      titlePrefixNumber,
      customAmount,
      setCustomAmount,
      handleValidation = () => {},
    },
    ref
  ) => {
    const handleCustomAmount = (e) => {
      e.preventDefault();
      setCustomAmount(e.target.value);
      onChangeInput(e);
    };

    const radioVariant = "button-selector";

    // global content keys
    const keys = {
      title: "title_selectAmount",
      text: "text_selectAmount",
      inputCustomAmount: "input_customAmount",
      radioCustomAmount: "radio_customAmount",
    };

    // default values - input field labels
    const labels = {
      inputCustomAmount: "Belopp i kronor",
    };

    // default values - input field labels
    const hints = {
      inputCustomAmount: "(Lägsta belopp 50 kr)",
    };

    // default values - other
    const texts = {
      title: "Välj belopp",
      radioCustomAmount: "Annat belopp",
    };

    return (
      <Card
        title={`${titlePrefixNumber}. ${findGlobalContent({
          globalData,
          key: keys.title,
          defaultValue: texts.title,
        })}`}
        text={findGlobalContent({ globalData, key: keys.text })}
      >
        <RadioGroup
          onValueChange={(value) => onChangeInputRadioOrCheckbox("amount", value)}
          value={amount}
          variant={radioVariant}
        >
          {amounts?.map((item, index) => {
            const radioProps = {
              key: `donation-amount-option-${index + 1}`,
              id: `donation-amount-option-${item.amount}`,
              value: item.amount,
              label: `${item.amount} kr`,
              variant: radioVariant,
            };

            if (index === 0) {
              return <Radio ref={ref} {...radioProps} />;
            } else {
              return <Radio {...radioProps} />;
            }
          })}

          <Radio
            id="donation-amount-option-custom"
            value={customAmount}
            label={findGlobalContent({
              globalData,
              key: keys.radioCustomAmount,
              defaultValue: texts.radioCustomAmount,
            })}
            variant={radioVariant}
          />
        </RadioGroup>

        {customAmount === amount && (
          <InputField
            type="number"
            name="amount"
            id="custom-amount-input"
            onChange={handleCustomAmount}
            onFocus={(event) => handleInputFocus(event)}
            onBlur={(event) => handleValidation(event)}
            value={amount}
            globalKey={keys.inputCustomAmount}
            defaultValues={{
              label: labels.inputCustomAmount,
              hint: hints.inputCustomAmount,
            }}
            min="50"
            onWheel={(e) => e.target.blur()}
          />
        )}
      </Card>
    );
  }
);

export default DonationAmountForm;

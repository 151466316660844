export const CardSymbol = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1631 13.0694H9.83691V20.9306H14.1631V13.0694Z"
      fill="#FF5F00"
    />
    <path
      d="M10.1116 17C10.1116 15.4028 10.8532 13.9861 11.9931 13.0694C11.1554 12.4028 10.0979 12 8.94421 12C6.21116 12 4 14.2361 4 17C4 19.7639 6.21116 22 8.94421 22C10.0979 22 11.1554 21.5972 11.9931 20.9306C10.8532 20.0278 10.1116 18.5972 10.1116 17Z"
      fill="#EB001B"
    />
    <path
      d="M20 17C20 19.7639 17.7888 22 15.0558 22C13.9021 22 12.8446 21.5972 12.0069 20.9306C13.1605 20.0139 13.8884 18.5972 13.8884 17C13.8884 15.4028 13.1468 13.9861 12.0069 13.0694C12.8446 12.4028 13.9021 12 15.0558 12C17.7888 12 20 14.25 20 17Z"
      fill="#F79E1B"
    />
    <path
      d="M9.83422 2.106L7.47766 7.90928H5.94018L4.78052 3.27799C4.71008 2.9927 4.64892 2.88826 4.43481 2.7681C4.08524 2.5723 3.50789 2.38869 3 2.27467L3.0345 2.106H5.50944C5.82491 2.106 6.10847 2.32276 6.18009 2.69775L6.79256 6.05584L8.30627 2.106H9.83422ZM15.8585 6.01452C15.8647 4.48291 13.8065 4.39849 13.8206 3.71431C13.825 3.50608 14.0172 3.28472 14.4376 3.22814C14.646 3.19996 15.2202 3.17846 15.8715 3.48786L16.1269 2.25728C15.777 2.1262 15.3267 2 14.7664 2C13.3288 2 12.3171 2.78884 12.3085 3.91833C12.2992 4.75379 13.0307 5.21997 13.5817 5.49757C14.1486 5.7819 14.3388 5.96454 14.3367 6.21888C14.3326 6.6082 13.8845 6.78005 13.4657 6.78678C12.7347 6.79836 12.3105 6.58258 11.9722 6.4203L11.7087 7.69166C12.0485 7.85259 12.6758 7.99299 13.3262 8C14.8542 8 15.8538 7.22099 15.8585 6.01452ZM19.6548 7.90928H21L19.8258 2.106H18.5841C18.3049 2.106 18.0695 2.27374 17.9652 2.53177L15.7825 7.90928H17.3098L17.6131 7.04241H19.4792L19.6548 7.90928ZM18.0319 5.853L18.7974 3.67407L19.238 5.853H18.0319ZM11.9124 2.106L10.7097 7.90928H9.25528L10.4584 2.106H11.9124Z"
      fill="#1434CB"
    />
  </svg>
);

export const SvenskInsamlingskontroll = (className) => (
  <svg
    className={className}
    width="82"
    height="21"
    viewBox="0 0 82 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M32.7282 20.5658H32.5279V0.742188H32.7282V20.5658Z" fill="black" />
    <path
      d="M34.6293 5.55732L35.3021 4.76554C35.7668 5.14308 36.254 5.38297 36.8455 5.38297C37.3099 5.38297 37.5917 5.20075 37.5917 4.90449V4.89007C37.5917 4.60822 37.414 4.46271 36.5504 4.24511C35.5091 3.98422 34.8375 3.70108 34.8375 2.69298V2.67856C34.8375 1.75699 35.5904 1.14742 36.6464 1.14742C37.3995 1.14742 38.0416 1.38076 38.5663 1.7937L37.9749 2.63531C37.5169 2.32331 37.067 2.13453 36.6304 2.13453C36.194 2.13453 35.9656 2.32986 35.9656 2.57762V2.59204C35.9656 2.92501 36.1872 3.03382 37.0803 3.25929C38.1297 3.52803 38.7198 3.89772 38.7198 4.78257V4.79699C38.7198 5.80508 37.9374 6.37009 36.8213 6.37009C36.039 6.36877 35.2487 6.10135 34.6293 5.55732Z"
      fill="black"
    />
    <path
      d="M38.8774 1.22064H40.1336L41.4846 4.7968L42.8356 1.22064H44.0611L41.9719 6.33318H40.9666L38.8774 1.22064Z"
      fill="black"
    />
    <path
      d="M48.5776 1.22064V2.21431H45.8074V3.24468H48.2453V4.23967H45.8074V5.30544H48.6137V6.2991H44.6767V1.22064H48.5776Z"
      fill="black"
    />
    <path
      d="M49.7525 1.22064H50.8017L53.2248 4.34716V1.22064H54.3476V6.2978H53.3797L50.8752 3.06903V6.2978H49.7525V1.22064Z"
      fill="black"
    />
    <path
      d="M55.2674 5.55724L55.9403 4.76546C56.4049 5.143 56.8922 5.38289 57.4836 5.38289C57.9481 5.38289 58.2299 5.20067 58.2299 4.90441V4.88999C58.2299 4.60814 58.0522 4.46263 57.1886 4.24503C56.1473 3.98416 55.4757 3.701 55.4757 2.6929V2.67848C55.4757 1.75691 56.2286 1.14734 57.2846 1.14734C58.0376 1.14734 58.6797 1.38068 59.2044 1.79362L58.613 2.63523C58.155 2.32323 57.7052 2.13446 57.2686 2.13446C56.8334 2.13446 56.6038 2.32978 56.6038 2.57754V2.59196C56.6038 2.92494 56.8253 3.03374 57.7184 3.25921C58.7678 3.52795 59.3592 3.89764 59.3592 4.78249V4.79691C59.3592 5.80501 58.5756 6.37001 57.4608 6.37001C56.6772 6.36869 55.8869 6.10127 55.2674 5.55724Z"
      fill="black"
    />
    <path
      d="M61.5167 1.22064V3.44002L63.614 1.22064H64.9877L62.8825 3.37447L65.0825 6.2978H63.7168L62.1134 4.1361L61.5154 4.74437V6.29648H60.3779V1.22064H61.5167Z"
      fill="black"
    />
    <path d="M35.0351 8.3376H36.1726V13.4148H35.0351V8.3376Z" fill="black" />
    <path
      d="M37.4769 8.3376H38.5263L40.948 11.4641V8.3376H42.0707V13.4148H41.1028L38.5996 10.1873V13.4148H37.4769V8.3376Z"
      fill="black"
    />
    <path
      d="M42.9718 12.6755L43.6446 11.885C44.1092 12.2625 44.5965 12.5011 45.188 12.5011C45.6526 12.5011 45.9342 12.3202 45.9342 12.0226V12.0082C45.9342 11.7251 45.7567 11.5809 44.8929 11.3632C43.8516 11.1024 43.1787 10.8192 43.1787 9.81112V9.7967C43.1787 8.87645 43.9318 8.26687 44.9876 8.26687C45.7407 8.26687 46.3842 8.49891 46.9088 8.91315L46.3173 9.75475C45.8595 9.44275 45.4097 9.25399 44.9731 9.25399C44.5378 9.25399 44.3096 9.44931 44.3096 9.69707V9.71149C44.3096 10.0458 44.5298 10.1546 45.4255 10.3787C46.4736 10.6475 47.065 11.0172 47.065 11.902V11.9164C47.065 12.9258 46.2827 13.4895 45.1666 13.4895C44.3829 13.4869 43.5927 13.2195 42.9718 12.6755Z"
      fill="black"
    />
    <path
      d="M49.5588 8.30236H50.6082L52.8244 13.4149H51.6349L51.1622 12.2757H48.9755L48.5029 13.4149H47.3428L49.5588 8.30236ZM50.7551 11.2899L50.0689 9.64342L49.3813 11.2899H50.7551Z"
      fill="black"
    />
    <path
      d="M54.664 8.3376L56.0231 10.4849L57.3821 8.3376H58.6077V13.4148H57.4768V10.1008L56.0231 12.269H55.9936L54.5531 10.1218V13.4148H53.4372V8.3376H54.664Z"
      fill="black"
    />
    <path
      d="M60.9494 8.3376V12.4001H63.5259V13.4148H59.8106V8.3376H60.9494Z"
      fill="black"
    />
    <path d="M64.3349 8.3376H65.4724V13.4148H64.3349V8.3376Z" fill="black" />
    <path
      d="M66.7754 8.3376H67.8247L70.2463 11.4641V8.3376H71.3691V13.4148H70.4013L67.8982 10.1886V13.4161H66.7754V8.3376Z"
      fill="black"
    />
    <path
      d="M72.2502 10.8914V10.8769C72.2502 9.43495 73.3944 8.25119 74.9603 8.25119C75.8909 8.25119 76.453 8.49895 76.9909 8.94729L76.2753 9.79544C75.8763 9.46902 75.5211 9.28156 74.9231 9.28156C74.0952 9.28156 73.4384 9.99863 73.4384 10.8625V10.8769C73.4384 11.8051 74.0886 12.4881 75.0031 12.4881C75.417 12.4881 75.7867 12.3858 76.0752 12.1826V11.4577H74.9297V10.4928H77.1752V12.6978C76.6439 13.1409 75.9122 13.5027 74.9671 13.5027C73.3584 13.5027 72.2502 12.3924 72.2502 10.8914Z"
      fill="black"
    />
    <path
      d="M77.9095 12.6755L78.5811 11.885C79.047 12.2625 79.5343 12.5011 80.1242 12.5011C80.5903 12.5011 80.8706 12.3202 80.8706 12.0226V12.0082C80.8706 11.7251 80.6917 11.5809 79.8293 11.3632C78.7879 11.1024 78.1152 10.8192 78.1152 9.81112V9.7967C78.1152 8.87645 78.868 8.26687 79.924 8.26687C80.6769 8.26687 81.3205 8.49891 81.8437 8.91315L81.2538 9.75475C80.7958 9.44275 80.3459 9.25399 79.9093 9.25399C79.4742 9.25399 79.2458 9.44931 79.2458 9.69707V9.71149C79.2458 10.0458 79.4674 10.1546 80.3606 10.3787C81.4099 10.6475 82 11.0172 82 11.902V11.9164C82 12.9258 81.2177 13.4895 80.1015 13.4895C79.3192 13.4869 78.5302 13.2195 77.9095 12.6755Z"
      fill="black"
    />
    <path
      d="M36.1805 15.4558V17.6752L38.2778 15.4558H39.6516L37.5464 17.6097L39.7478 20.533H38.3807L36.7773 18.3726L36.1792 18.9821V20.5343H35.0417V15.4558H36.1805Z"
      fill="black"
    />
    <path
      d="M39.6369 18.0096V17.9951C39.6369 16.5518 40.797 15.3694 42.3925 15.3694C43.9878 15.3694 45.1332 16.5374 45.1332 17.9808V17.9951C45.1332 19.4385 43.9731 20.621 42.3791 20.621C40.7825 20.621 39.6369 19.4528 39.6369 18.0096ZM43.9437 18.0096V17.9951C43.9437 17.1247 43.2935 16.3998 42.3778 16.3998C41.462 16.3998 40.8265 17.1103 40.8265 17.9808V17.9951C40.8265 18.8656 41.4765 19.5905 42.3925 19.5905C43.3083 19.5905 43.9437 18.8801 43.9437 18.0096Z"
      fill="black"
    />
    <path
      d="M45.9983 15.4558H47.0477L49.4707 18.5824V15.4558H50.5934V20.533H49.6257L47.1211 17.3055V20.533H45.9983V15.4558Z"
      fill="black"
    />
    <path
      d="M52.9552 16.4862H51.3811V15.4558H55.6652V16.4862H54.0912V20.533H52.9538V16.4862H52.9552Z"
      fill="black"
    />
    <path
      d="M56.4556 15.4558H58.82C59.4768 15.4558 59.9868 15.6367 60.3272 15.971C60.6141 16.2542 60.7704 16.6527 60.7704 17.1324V17.1469C60.7704 17.9674 60.3191 18.4814 59.6622 18.7214L60.9252 20.5343H59.5955L58.4875 18.9101H57.5931V20.5343H56.4556V15.4558ZM58.7465 17.9217C59.3005 17.9217 59.6182 17.6319 59.6182 17.2046V17.1902C59.6182 16.7117 59.2793 16.4652 58.7238 16.4652H57.5944V17.9229H58.7465V17.9217Z"
      fill="black"
    />
    <path
      d="M61.51 18.0096V17.9951C61.51 16.5518 62.6702 15.3694 64.2642 15.3694C65.8595 15.3694 67.0049 16.5374 67.0049 17.9808V17.9951C67.0049 19.4385 65.8462 20.621 64.2509 20.621C62.6554 20.621 61.51 19.4528 61.51 18.0096ZM65.8154 18.0096V17.9951C65.8154 17.1247 65.1654 16.3998 64.2494 16.3998C63.3336 16.3998 62.6982 17.1103 62.6982 17.9808V17.9951C62.6982 18.8656 63.3484 19.5905 64.2642 19.5905C65.18 19.5905 65.8154 18.8801 65.8154 18.0096Z"
      fill="black"
    />
    <path
      d="M67.9262 15.4558H69.0635V19.5171H71.6415V20.5316H67.9262V15.4558Z"
      fill="black"
    />
    <path
      d="M72.2784 15.4558H73.4157V19.5171H75.9937V20.5316H72.2784V15.4558Z"
      fill="black"
    />
    <path
      d="M12.5012 16.9464H13.3022V18.5116L14.7814 16.9464H15.7493L14.2647 18.4658L15.816 20.5265H14.8535L13.7241 19.0033L13.3022 19.4333V20.5278H12.5012V16.9464Z"
      fill="#D71920"
    />
    <path
      d="M15.6011 18.7463V18.7358C15.6011 17.7186 16.4181 16.8849 17.5422 16.8849C18.6677 16.8849 19.474 17.7081 19.474 18.7253V18.7358C19.474 19.7531 18.657 20.5868 17.5315 20.5868C16.4088 20.5868 15.6011 19.7636 15.6011 18.7463ZM18.6369 18.7463V18.7358C18.6369 18.1223 18.1791 17.6111 17.533 17.6111C16.8881 17.6111 16.4395 18.1118 16.4395 18.7253V18.7358C16.4395 19.3493 16.8973 19.8606 17.5435 19.8606C18.1883 19.8618 18.6369 19.3598 18.6369 18.7463Z"
      fill="#D71920"
    />
    <path
      d="M19.9025 16.9464H20.6421L22.3496 19.1501V16.9464H23.1414V20.5252H22.4591L20.6943 18.2495V20.5252H19.9025V16.9464Z"
      fill="#D71920"
    />
    <path
      d="M24.6246 17.6726H23.5151V16.9464H26.535V17.6726H25.4269V20.5252H24.6246V17.6726Z"
      fill="#D71920"
    />
    <path
      d="M26.4389 18.7463V18.7358C26.4389 17.7186 27.2559 16.8849 28.3814 16.8849C29.5067 16.8849 30.3132 17.7081 30.3132 18.7253V18.7358C30.3132 19.7531 29.496 20.5868 28.3707 20.5868C27.2466 20.5868 26.4389 19.7636 26.4389 18.7463ZM29.4748 18.7463V18.7358C29.4748 18.1223 29.0168 17.6111 28.3707 17.6111C27.7245 17.6111 27.2772 18.1118 27.2772 18.7253V18.7358C27.2772 19.3493 27.7352 19.8606 28.3814 19.8606C29.0262 19.8618 29.4748 19.3598 29.4748 18.7463Z"
      fill="#D71920"
    />
    <path
      d="M22.8369 0.79716C19.2003 0.79716 16.1391 3.26561 15.3007 6.58615C14.4623 3.26561 11.4011 0.79716 7.76452 0.79716C3.48309 0.79716 0 4.21733 0 8.42142C0 12.6137 3.46439 16.026 7.72847 16.0444L4.71932 20.5238H8.93399C11.1154 17.2752 13.9617 13.0371 14.2501 12.6045C14.724 11.8927 15.0845 11.1035 15.2994 10.2554C16.1378 13.5759 19.199 16.0444 22.8356 16.0444C27.1171 16.0444 30.6001 12.6242 30.6001 8.42011C30.6014 4.21733 27.1184 0.79716 22.8369 0.79716ZM7.76452 12.5901C5.42288 12.5901 3.5178 10.7194 3.5178 8.42011C3.5178 6.12077 5.42288 4.2501 7.76452 4.2501C10.1062 4.2501 12.0112 6.12077 12.0112 8.42011C12.0112 10.7207 10.1062 12.5901 7.76452 12.5901ZM22.8369 12.5901C20.4954 12.5901 18.5903 10.7194 18.5903 8.42011C18.5903 6.12077 20.4954 4.2501 22.8369 4.2501C25.1786 4.2501 27.0837 6.12077 27.0837 8.42011C27.0837 10.7207 25.1786 12.5901 22.8369 12.5901Z"
      fill="#D71920"
    />
  </svg>
);

// functions
import { findGlobalContent } from "../../../functions/findGlobalContent";

// components
import Card from "../../Card/Card";
import RadioGroup from "../../RadioGroup/RadioGroup";
import Radio from "../../Radio/Radio";

const DonationTypeForm = ({
  titlePrefixNumber,
  globalData = {},
  donationType,
  onChangeInputRadioOrCheckbox = () => {},
}) => {
  const radioVariant = "button-selector";

  // global content keys
  const keys = {
    title: "title_donationType",
    text: "text_donationType",
    radioDonation: "radio_donation",
    radioMonthly: "radio_monthly",
  };

  // default values
  const texts = {
    title: "Typ av gåva",
    radioDonation: "Engångsgåva",
    radioMonthly: "Månadsgåva",
  };

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.title,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({ globalData, key: keys.text })}
    >
      <RadioGroup
        onValueChange={(value) => onChangeInputRadioOrCheckbox("donation_type", value)}
        value={donationType}
        name="donation_type"
        variant={radioVariant}
      >
        <Radio
          id="donation-type-option-one-time"
          value="donation"
          variant={radioVariant}
          label={findGlobalContent({
            globalData,
            key: keys.radioDonation,
            defaultValue: texts.radioDonation,
          })}
        />

        <Radio
          id="donation-type-option-monthly"
          value="monthly"
          variant={radioVariant}
          label={findGlobalContent({
            globalData,
            key: keys.radioMonthly,
            defaultValue: texts.radioMonthly,
          })}
        />
      </RadioGroup>
    </Card>
  );
};

export default DonationTypeForm;

// styles
import styles from "./Card.module.scss";

// components
import Typography from "../Typography/Typography";

const Card = ({ title, text, children }) => {
  return (
    <div className={styles["card"]}>
      {title && <Typography variant="headline-2" className={styles["title"]}>{title}</Typography>}

      {text && <Typography>{text}</Typography>}

      <div className={styles["inner-wrapper"]}>{children}</div>
    </div>
  );
};
export default Card;

import { useContext } from "react";

// functions
import { findGlobalContent } from "../../../functions/findGlobalContent";
import { AppdataContext } from "../../../functions/appdataContext";

// components
import InputField from "../../InputField/InputField";
import Card from "../../Card/Card";

const CompanyDetailsForm = ({
  globalData = {},
  titlePrefixNumber,
  onChangeInput = () => {},
  handleInputFocus = () => {},
  handleInputBlur = () => {},
  handleValidation = () => {},
}) => {
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;

  // global content keys
  const keys = {
    title: "title_companyDetails",
    text: "text_companyDetails",
    companyname: "input_companyName",
    orgnumber: "input_orgNumber",
    address: "input_address",
    zip: "input_zip",
    city: "input_city",
    firstname: "input_firstnameCompany",
    lastname: "input_lastnameCompany",
    email: "input_emailCompany",
    phone: "input_phoneCompany",
  };

  // default values - input field labels
  const labels = {
    companyname: "Företagsnamn",
    orgnumber: "Organisationsnummer",
    address: "Adress",
    zip: "Postnummer",
    city: "Ort",
    firstname: "Förnamn kontaktperson",
    lastname: "Efternamn kontaktperson",
    email: "E-post till kontaktperson",
    phone: "Mobilnummer till kontaktperson",
  };

  // default values - other
  const texts = {
    title: "Kontaktuppgifter",
  };

  return (
    <Card
      title={`${titlePrefixNumber}. ${findGlobalContent({
        globalData,
        key: keys.titlecompanyname,
        defaultValue: texts.title,
      })}`}
      text={findGlobalContent({ globalData, key: keys.text })}
    >
      <InputField
        type="text"
        name="companyname"
        id="company-name-input"
        value={formData.companyname}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.companyname}
        defaultValues={{
          label: labels.companyname,
        }}
      />

      <InputField
        type="text"
        name="orgnumber"
        id="orgnumber-input"
        value={formData?.orgnumber}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.orgnumber}
        defaultValues={{
          label: labels.orgnumber,
        }}
      />

      <InputField
        type="text"
        name="address"
        id="address-input"
        value={formData.address}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.address}
        defaultValues={{
          label: labels.address,
        }}
      />

      <InputField
        type="text"
        name="zip"
        id="zip-code-input"
        value={formData.zip}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.zip}
        defaultValues={{
          label: labels.zip,
        }}
      />

      <InputField
        type="text"
        name="city"
        id="city-input"
        value={formData.city}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.city}
        defaultValues={{
          label: labels.city,
        }}
      />

      <InputField
        type="text"
        name="firstname"
        id="firstname-input"
        value={formData.firstname}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.firstname}
        defaultValues={{
          label: labels.firstname,
        }}
      />

      <InputField
        type="text"
        name="lastname"
        id="lastname-input"
        value={formData.lastname}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.lastname}
        defaultValues={{
          label: labels.lastname,
        }}
      />

      <InputField
        type="text"
        name="email"
        id="email-input"
        value={formData.email}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleInputBlur(event)}
        globalKey={keys.email}
        defaultValues={{
          label: labels.email,
        }}
      />

      <InputField
        type="text"
        name="phone"
        id="phone-input"
        value={formData.phone}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
        globalKey={keys.phone}
        defaultValues={{
          label: labels.phone,
        }}
      />
    </Card>
  );
};

export default CompanyDetailsForm;

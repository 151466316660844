// components
import InputField from "../../../../InputField/InputField";

const MethodPostForm = ({
  formData,
  onChangeInput = () => {},
  handleInputFocus = () => {},
  handleValidation = () => {},
}) => {
  const recipient = formData?.delivery_recipient;
  const undertakerIsRecipient = recipient === "undertaker";
  const relationIsRecipient = recipient === "relation";

  // global content keys
  const keys = {
    firstname: relationIsRecipient
      ? "input_firstnameRelation"
      : undertakerIsRecipient
      ? "input_nameUndertaker"
      : "input_firstnameOtherRecipient",
    lastname: relationIsRecipient
      ? "input_lastnameRelation"
      : "input_lastnameOtherRecipient",
    address: relationIsRecipient
      ? "input_addressRelation"
      : undertakerIsRecipient
      ? "input_addressUndertaker"
      : "input_addressOtherRecipient",
    zip: relationIsRecipient
      ? "input_zipRelation"
      : undertakerIsRecipient
      ? "input_zipUndertaker"
      : "input_zipOtherRecipient",
    city: relationIsRecipient
      ? "input_cityRelation"
      : undertakerIsRecipient
      ? "input_cityUndertaker"
      : "input_cityOtherRecipient",
  };

  // default values - input field labels
  const labels = {
    firstname: relationIsRecipient
      ? "Förnamn anhörig"
      : undertakerIsRecipient
      ? "Begravningsbyrå eller kyrka"
      : "Förnamn mottagare",
    lastname: relationIsRecipient ? "Efternamn anhörig" : "Efternamn mottagare",
    address: relationIsRecipient
      ? "Adress anhörig"
      : undertakerIsRecipient
      ? "Adress"
      : "Adress mottagare",
    zip: relationIsRecipient
      ? "Postnummer anhörig"
      : undertakerIsRecipient
      ? "Postnummer"
      : "Postnummer mottagare",
    city: relationIsRecipient
      ? "Ort anhörig"
      : undertakerIsRecipient
      ? "Ort"
      : "Ort mottagare",
  };

  return (
    <>
      <InputField
        type="text"
        name="recipient_firstname"
        id="recipient_firstname"
        globalKey={keys.firstname}
        defaultValues={{
          label: labels.firstname,
        }}
        value={formData.recipient_firstname}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
      />

      {!undertakerIsRecipient && (
        <InputField
          type="text"
          name="recipient_lastname"
          id="recipient_lastname"
          globalKey={keys.lastname}
          defaultValues={{
            label: labels.lastname,
          }}
          value={formData.recipient_lastname}
          onChange={onChangeInput}
          onFocus={(event) => handleInputFocus(event)}
          onBlur={(event) => handleValidation(event)}
        />
      )}

      <InputField
        type="text"
        name="recipient_address"
        id="recipient_address"
        globalKey={keys.address}
        defaultValues={{
          label: labels.address,
        }}
        value={formData.recipient_address}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
      />

      <InputField
        type="text"
        name="recipient_zip"
        id="recipient_zip"
        globalKey={keys.zip}
        defaultValues={{
          label: labels.zip,
        }}
        value={formData.recipient_zip}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
      />

      <InputField
        type="text"
        name="recipient_city"
        id="recipient_city"
        globalKey={keys.city}
        defaultValues={{
          label: labels.city,
        }}
        value={formData.recipient_city}
        onChange={onChangeInput}
        onFocus={(event) => handleInputFocus(event)}
        onBlur={(event) => handleValidation(event)}
      />
    </>
  );
};

export default MethodPostForm;

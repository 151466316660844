import { Root } from "@radix-ui/react-radio-group";
import clsx from "clsx";

// styles
import styles from "./RadioGroup.module.scss";

// components
import Typography from "../Typography/Typography";

// functions
import { useContext } from "react";
import { AppdataContext } from "../../functions/appdataContext";

const RadioGroup = ({
  className,
  name,
  children,
  variant = "default",
  label,
  required,
  ...props
}) => {
  const appdata = useContext(AppdataContext);
  const { formErrors } = appdata;
  const error = formErrors[name]

  return (
    <div className={styles["radio-group-container"]}>
      {label && <Typography variant="label">{label}</Typography>}
      <Root
        className={clsx(styles["radio-group"], styles[variant], className)}
        required={required}
        name={name}
        {...props}
      >
        {children}
      </Root>

      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

export default RadioGroup;

import { forwardRef } from "react";
import { Item, Indicator } from "@radix-ui/react-radio-group";
import clsx from "clsx";

// styles
import styles from "./Radio.module.scss";

// components
import Typography from "../Typography/Typography";
import Icon from "../Icon/Icon";

const Radio = forwardRef(
  (
    { className, id, name, label, variant = "default", imageProps, ...props },
    ref
  ) => {
    return (
      <Item
        className={clsx(styles["radio-button"], styles[variant], className)}
        id={id}
        {...props}
        ref={ref}
      >
        {variant === "image-selector" && (
          <div className={styles["thumbnail-wrapper"]}>
            <img {...imageProps} alt={imageProps?.alt} />
          </div>
        )}

        {variant === "image-selector" ? (
          <Indicator className={styles["indicator"]}>
            <Icon variant="checkmark-round" />
          </Indicator>
        ) : (
          <div className={styles["indicator-wrapper"]}>
            <Indicator className={styles["indicator"]} />
          </div>
        )}

        <label className={styles["label"]}>
          <Typography
            variant={variant === "default" ? "radio-text" : "button"}
          >
            {label}
          </Typography>
        </label>

        {Boolean(
          variant === "button-selector" &&
            (name === "creditcard" || name === "swish")
        ) && (
          <Icon
            className={styles["icon"]}
            variant={name === "creditcard" ? "card-symbol" : "swish-symbol"}
            size="large"
          />
        )}
      </Item>
    );
  }
);

export default Radio;

export const initialData = {
  "data": {
      "success": true,
      "data": {
          "campaign_id": "701MT0000004oQrYAI",
          "donation_type": "tribute",
          "amounts": [
            {
                "amount": "600",
                "is_default": false
            },
            {
                "amount": "400",
                "is_default": true
            },
            {
                "amount": "200",
                "is_default": false
            }
          ],
          "thank_you_url": "http://localhost:9070/ktk-donations-formular/",
          "current_url": "http://localhost:9070/ktk-donations-formular/",
          "infoboxes": [
            {
              "image": {
                  "ID": 45068,
                  "id": 45068,
                  "title": "movement-bag-premie 1",
                  "filename": "movement-bag-premie-1.jpg",
                  "filesize": 17157,
                  "url": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                  "link": "http://localhost:9070/ktk-donations-formular-memorial/movement-bag-premie-1/",
                  "alt": "",
                  "author": "30",
                  "description": "",
                  "caption": "",
                  "name": "movement-bag-premie-1",
                  "status": "inherit",
                  "uploaded_to": 44957,
                  "date": "2023-01-10 09:33:44",
                  "modified": "2023-01-10 09:33:44",
                  "menu_order": 0,
                  "mime_type": "image/jpeg",
                  "type": "image",
                  "subtype": "jpeg",
                  "icon": "http://localhost:9070/wp-includes/images/media/default.png",
                  "width": 140,
                  "height": 119,
                  "sizes": {
                      "thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "thumbnail-width": 140,
                      "thumbnail-height": 119,
                      "medium": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "medium-width": 140,
                      "medium-height": 119,
                      "medium_large": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "medium_large-width": 140,
                      "medium_large-height": 119,
                      "large": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "large-width": 140,
                      "large-height": 119,
                      "1536x1536": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "1536x1536-width": 140,
                      "1536x1536-height": 119,
                      "2048x2048": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "2048x2048-width": 140,
                      "2048x2048-height": 119,
                      "woo-square": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woo-square-width": 140,
                      "woo-square-height": 119,
                      "woocommerce_thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woocommerce_thumbnail-width": 140,
                      "woocommerce_thumbnail-height": 119,
                      "woocommerce_single": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woocommerce_single-width": 140,
                      "woocommerce_single-height": 119,
                      "woocommerce_gallery_thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1-100x100.jpg",
                      "woocommerce_gallery_thumbnail-width": 100,
                      "woocommerce_gallery_thumbnail-height": 100
                  }
              },
              "content": `<p><strong>At vero</strong> eos et <b>accusamus</b> et iusto odio <a href="#">dignissimos ducimus</a>.</p><p>Accuasamus eos et.</p>\n<ul>\n<li>Oluptatum deleniti</li>\n<li>Excepturi sint occaecati pluine cupiditate non provident,</li>\n</ul>\n`,
              "showOnMobile": true
            },
            {
              "image": {
                  "ID": 45068,
                  "id": 45068,
                  "title": "movement-bag-premie 1",
                  "filename": "movement-bag-premie-1.jpg",
                  "filesize": 17157,
                  "url": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                  "link": "http://localhost:9070/ktk-donations-formular-memorial/movement-bag-premie-1/",
                  "alt": "",
                  "author": "30",
                  "description": "",
                  "caption": "",
                  "name": "movement-bag-premie-1",
                  "status": "inherit",
                  "uploaded_to": 44957,
                  "date": "2023-01-10 09:33:44",
                  "modified": "2023-01-10 09:33:44",
                  "menu_order": 0,
                  "mime_type": "image/jpeg",
                  "type": "image",
                  "subtype": "jpeg",
                  "icon": "http://localhost:9070/wp-includes/images/media/default.png",
                  "width": 140,
                  "height": 119,
                  "sizes": {
                      "thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "thumbnail-width": 140,
                      "thumbnail-height": 119,
                      "medium": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "medium-width": 140,
                      "medium-height": 119,
                      "medium_large": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "medium_large-width": 140,
                      "medium_large-height": 119,
                      "large": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "large-width": 140,
                      "large-height": 119,
                      "1536x1536": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "1536x1536-width": 140,
                      "1536x1536-height": 119,
                      "2048x2048": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "2048x2048-width": 140,
                      "2048x2048-height": 119,
                      "woo-square": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woo-square-width": 140,
                      "woo-square-height": 119,
                      "woocommerce_thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woocommerce_thumbnail-width": 140,
                      "woocommerce_thumbnail-height": 119,
                      "woocommerce_single": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1.jpg",
                      "woocommerce_single-width": 140,
                      "woocommerce_single-height": 119,
                      "woocommerce_gallery_thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/movement-bag-premie-1-100x100.jpg",
                      "woocommerce_gallery_thumbnail-width": 100,
                      "woocommerce_gallery_thumbnail-height": 100
                  }
              },
              "content": `<p><strong>At vero</strong> eos et <b>accusamus</b> et iusto odio <a href="#">dignissimos ducimus</a>.</p><p>Accuasamus eos et.</p>\n<ul>\n<li>Oluptatum deleniti</li>\n<li>Excepturi sint occaecati pluine cupiditate non provident,</li>\n</ul>\n`,
              "showOnMobile": true
            },
          ],
          "pdf_array": [
            {
                "name": "Memorial",
                "text_x": "0",
                "text_y": "1600",
                "text_width": "70",
                "preview": {
                    "ID": 45000,
                    "id": 45000,
                    "title": "minnesgåva-bild",
                    "filename": "minnesgåva-bild.jpeg",
                    "filesize": 58618,
                    "url": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild.jpeg",
                    "link": "http://localhost:9070/ktk-donations-formular-memorial/minnesga%cc%8ava-bild/",
                    "alt": "",
                    "author": "30",
                    "description": "",
                    "caption": "",
                    "name": "minnesga%cc%8ava-bild",
                    "status": "inherit",
                    "uploaded_to": 44957,
                    "date": "2023-01-02 16:11:51",
                    "modified": "2023-01-02 16:11:51",
                    "menu_order": 0,
                    "mime_type": "image/jpeg",
                    "type": "image",
                    "subtype": "jpeg",
                    "icon": "http://localhost:9070/wp-includes/images/media/default.png",
                    "width": 791,
                    "height": 717,
                    "sizes": {
                        "thumbnail": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild-150x150.jpeg",
                        "thumbnail-width": 150,
                        "thumbnail-height": 150,
                        "medium": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild-300x272.jpeg",
                        "medium-width": 300,
                        "medium-height": 272,
                        "medium_large": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild-768x696.jpeg",
                        "medium_large-width": 768,
                        "medium_large-height": 696,
                        "large": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild.jpeg",
                        "large-width": 791,
                        "large-height": 717,
                        "1536x1536": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild.jpeg",
                        "1536x1536-width": 791,
                        "1536x1536-height": 717,
                        "2048x2048": "http://localhost:9070/wp-content/uploads/2023/01/minnesgåva-bild.jpeg",
                        "2048x2048-width": 791,
                        "2048x2048-height": 717,
                    }
                },
                "pdf": {
                    "ID": 44999,
                    "id": 44999,
                    "title": "Minnesgava-1-1",
                    "filename": "Minnesgava-1-1.pdf",
                    "filesize": 176971,
                    "url": "http://localhost:9070/wp-content/uploads/2023/01/Minnesgava-1-1.pdf",
                    "link": "http://localhost:9070/ktk-donations-formular-memorial/minnesgava-1-1/",
                    "alt": "",
                    "author": "30",
                    "description": "",
                    "caption": "",
                    "name": "minnesgava-1-1",
                    "status": "inherit",
                    "uploaded_to": 44957,
                    "date": "2023-01-02 16:07:22",
                    "modified": "2023-01-02 16:07:22",
                    "menu_order": 0,
                    "mime_type": "application/pdf",
                    "type": "application",
                    "subtype": "pdf",
                    "icon": "http://localhost:9070/wp-includes/images/media/document.png"
                }
            }
        ],
          "global": {
              "": null,
              "title_personalDetails": "Dina personuppgifter",
              "title_selectAmount": "Välj belopp",
              "title_delivery": "Leverans",
              "title_recurringPayment": "Betalning via autogiro",
              "title_paymentType": "Välj betalsätt",
              "title_donationType": "Typ av gåva",
              "title_createMemorial": "Skapa minnesblad",
              "title_createTribute": "Skapa gåvokort",
              "title_companyDetails": "Kontaktuppgifter",
              "toggle_showMonthlyGiftAlert": false,
              "text_inputErrorAlert": "<p>Input error! <strong>Error!</strong></p>",
              "text_monthlyGiftAlert": "<p>Just nu har vi högt tryck på sidan och det kan ta tid mellan stegen när du fyller i dina uppgifter. Om du upplever problem ber vi dig att vänta några minuter och testa igen. Du är välkommen att kontakta oss på 08-588 891 58 eller givarservice@kvinnatillkvinna.se om du behöver hjälp.</p>",
              "text_personalDetails": "När du går vidare sparar vi dina uppgifter så vi kan skicka dig en bekräftelse.",
              "text_selectAmount": "",
              "text_delivery": "",
              "text_recurringPayment": "",
              "text_paymentType": "",
              "text_donationType": "",
              "text_createMemorial": "",
              "text_createTribute": "",
              "text_companyDetails": "",
              "text_modalUseBankId": "Hej! Vänligen bekräfta med BankID för att hämta bankkonto.",
              "text_getBankAccountHint": 'Fyll i bank samt personnummer under "Dina personuppgifter" för att kunna hämta ditt bankkonto med BankID.',
              "label_sendTo": "Skicka till",
              "text_deliverySelfMemorial": "Minnesbladet skickas till samma adress angivet i dina personuppgifter.",
              "text_deliverySelfTribute": "Gåvokortet skickas till samma adress angivet i dina personuppgifter.",
              "label_sendMemorialCertificate": "Skicka minnesblad",
              "label_sendTributeCertificate": "Skicka gåvokort",
              "label_certificateMotif" : "Motiv",
              "input_email": {
                  "label": "Din e-post",
                  "placeholder": ""
              },
              "input_phone": {
                  "label": "Ditt telefonnummer",
                  "placeholder": ""
              },
              "input_ssn": {
                  "label": "Personnummer",
                  "placeholder": "ååååmmddxxxx"
              },
              "input_firstname": {
                  "label": "Förnamn",
                  "placeholder": ""
              },
              "input_lastname": {
                  "label": "Efternamn",
                  "placeholder": ""
              },
              "input_address": {
                  "label": "Adress",
                  "placeholder": ""
              },
              "input_zip": {
                  "label": "Postnummer",
                  "placeholder": ""
              },
              "input_city": {
                  "label": "Ort",
                  "placeholder": ""
              },
              "input_undertaker": {
                "label": "Begravningsbyrå eller kyrka",
                "placeholder": ""
              },
              "input_bankClearing": {
                "label": "Clearingnummer",
                "placeholder": ""
              },
              "input_bankAccount": {
                "label": "Bankkontonummer",
                "placeholder": ""
              },
              "input_memorialGreeting": {
                "label": "Din hälsning",
                "placeholder": "Skriv din hälsning och ditt/era namn här"
              },
              "input_tributeGreeting": {
                "label": "Din hälsning",
                "placeholder": "Skriv din hälsning här"
              },
              "input_funeralDate": {
                "label": "Datum för begravning",
              },
              "input_emailRelation": {
                "label": "Anhörigs e-post",
                "placeholder": "",
              },
              "input_emailOther": {
                "label": "Mottagarens e-post",
                "placeholder": "",
              },
              "input_customAmount": {
                "label": "Belopp i kronor",
                "placeholder": "",
                "hint": "(Lägsta belopp: 50 kr)",
              },
              "input_companyName": {
                "label": "Företagsnamn",
                "placeholder": "",
              },
              "input_orgNumber": {
                "label": "Organisationsnummer",
                "placeholder": "",
              },
              "input_firstnameCompany": {
                "label": "Förnamn kontaktperson",
                "placeholder": "",
              },
              "input_lastnameCompany": {
                "label": "Efternamn kontaktperson",
                "placeholder": "",
              },
              "input_emailCompany": {
                "label": "E-post till kontaktperson",
                "placeholder": "",
              },
              "input_phoneCompany": {
                "label": "Mobilnummer till kontaktperson",
                "placeholder": "",
              },
              "input_firstnameRelation": {
                "label": "Förnamn anhörig",
                "placeholder": "",
              },
              "input_nameUndertaker": {
                "label": "Begravningsbyrå eller kyrka",
                "placeholder": "",
              },
              "input_firstnameOtherRecipient": {
                "label": "Förnamn mottagare",
                "placeholder": "",
              },
              "input_lastnameRelation": {
                "label": "Efternamn anhörig",
                "placeholder": "",
              },
              "input_lastnameOtherRecipient": {
                "label": "Efternamn mottagare",
                "placeholder": "",
              },
              "input_addressRelation": {
                "label": "Adress anhörig",
                "placeholder": "",
              },
              "input_addressUndertaker": {
                "label": "Address",
                "placeholder": "",
              },
              "input_addressOtherRecipient": {
                "label": "Address mottagare",
                "placeholder": "",
              },
              "input_zipRelation": {
                "label": "Postnummer anhörig",
                "placeholder": "",
              },
              "input_zipUndertaker": {
                "label": "Postnummer",
                "placeholder": "",
              },
              "input_zipOtherRecipient" : {
                "label": "Postnummer mottagare",
                "placeholder": "",
              },
              "input_cityRelation": {
                "label": "Ort anhörig",
                "placeholder": "",
              },
              "input_cityUndertaker": {
                "label": "Ort",
                "placeholder": "",
              },
              "input_cityOtherRecipient": {
                "label": "Ort mottagare",
                "placeholder": "",
              },
              "select_chooseBank": {
                "label": "Bank",
                "placeholderOption": "Välj bank",
              },
              "button_getBankAccount": "Hämta bankkonto med BankID",
              "button_manualBankAccount": "Fyll i kontouppgifterna själv",
              "button_manualContactDetails": "Fyll i kontaktuppgifterna själv",
              "button_previewMemorial": "Förhandsgranska minnesblad",
              "button_previewTribute": "Förhandsgranska gåvokort",
              "button_approveMemorial" : "Godkänn minnesbladet",
              "button_approveTribute" : "Godkänn gåvokortet",
              "button_editMemorial" : "Ändra minnesbladet",
              "button_editTribute" : "Ändra gåvokortet",
              "button_toPayment": "Till betalning",
              "button_registerMonthlyDonor": "Bli månadsgivare",
              "button_modalUseBankIdMobile": "Använd Mobilt BankID",
              "button_modalBankIdOtherUnit": "Öppna BankID på annan enhet",
              "checkbox_approvalRecurringPayment": `<p>Jag godkänner <a href="#">villkoren för autogiro</a></p>`,
              "radio_customAmount": "Annat belopp",
              "radio_deliveryDigital": "Digital med e-post",
              "radio_deliveryPost": "Fysiskt med post (25 kr för porto tillkommer)",
              "radio_undertakerOrChurch": "Begravningsbyrå eller kyrka",
              "radio_relation": "Anhörig",
              "radio_other": "Mottagaren",
              "radio_self": "Mig",
              "radio_invoice": "Faktura",
              "radio_swish": "Swish",
              "radio_creditcard": "Bankkort",
              "radio_monthly": "Månadsgåva",
              "radio_donation": "Engångsgåva",
              "richtext_privacyPolicy": `<p>När du går till betalning kommer dina uppgifter behandlas av Kvinna till Kvinna. Läs mer om <a href="#">hur vi behandlar dina uppgifter</a>.</p>`,
          },
          "billecta": {
            "banks": [
                {
                    "id": "OEB",
                    "name": "DanskeBank"
                },
                {
                    "id": "SHB",
                    "name": "Handelsbanken"
                },
                {
                    "id": "ICA",
                    "name": "ICA"
                },
                {
                    "id": "LFB",
                    "name": "Lansforsakringar"
                },
                {
                    "id": "NB",
                    "name": "Nordea"
                },
                {
                    "id": "SBAB",
                    "name": "SBAB"
                },
                {
                    "id": "SEB",
                    "name": "SEB"
                },
                {
                    "id": "SKB",
                    "name": "Skandia"
                },
                {
                    "id": "SYD",
                    "name": "Sparbanken Syd"
                },
                {
                    "id": "FSPA",
                    "name": "Swedbank"
                },
                {
                    "id": "FSPASB",
                    "name": "Swedbank Sparbankerna"
                },
                {
                    "id": "OTHER",
                    "name": "Other"
                }
            ]
        }
      }
  },
  "status": 200,
  "statusText": "OK",
  "headers": {
      "cache-control": "no-cache, must-revalidate, max-age=0",
      "connection": "Keep-Alive",
      "content-encoding": "gzip",
      "content-length": "516",
      "content-type": "application/json; charset=UTF-8",
      "date": "Thu, 22 Dec 2022 20:22:04 GMT",
      "expires": "Wed, 11 Jan 1984 05:00:00 GMT",
      "keep-alive": "timeout=5, max=92",
      "referrer-policy": "strict-origin-when-cross-origin",
      "server": "Apache/2.4.54 (Debian)",
      "vary": "Accept-Encoding",
      "x-content-type-options": "nosniff",
      "x-frame-options": "SAMEORIGIN",
      "x-powered-by": "PHP/7.4.30",
      "x-robots-tag": "noindex"
  },
  "config": {
      "transitional": {
          "silentJSONParsing": true,
          "forcedJSONParsing": true,
          "clarifyTimeoutError": false
      },
      "adapter": [
          "xhr",
          "http"
      ],
      "transformRequest": [
          null
      ],
      "transformResponse": [
          null
      ],
      "timeout": 0,
      "xsrfCookieName": "XSRF-TOKEN",
      "xsrfHeaderName": "X-XSRF-TOKEN",
      "maxContentLength": -1,
      "maxBodyLength": -1,
      "env": {},
      "headers": {
          "Accept": "application/json, text/plain, */*"
      },
      "method": "post",
      "url": "http://localhost:9070/wp-admin/admin-ajax.php",
      "data": {}
  },
  "request": {}
}

export const Loader = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33333 12C5.33333 12.9205 4.58714 13.6667 3.66667 13.6667C2.74619 13.6667 2 12.9205 2 12C2 11.0795 2.74619 10.3333 3.66667 10.3333C4.58714 10.3333 5.33333 11.0795 5.33333 12Z"
      fill="currentColor"
      fillOpacity="0.12"
    />
    <path
      d="M22 12C22 12.9205 21.2538 13.6667 20.3333 13.6667C19.4129 13.6667 18.6667 12.9205 18.6667 12C18.6667 11.0795 19.4129 10.3333 20.3333 10.3333C21.2538 10.3333 22 11.0795 22 12Z"
      fill="currentColor"
      fillOpacity="0.7"
    />
    <path
      d="M13.6667 3.66667C13.6667 4.58714 12.9205 5.33333 12 5.33333C11.0795 5.33333 10.3333 4.58714 10.3333 3.66667C10.3333 2.74619 11.0795 2 12 2C12.9205 2 13.6667 2.74619 13.6667 3.66667Z"
      fill="currentColor"
      fillOpacity="0.4"
    />
    <path
      d="M13.6667 20.3333C13.6667 21.2538 12.9205 22 12 22C11.0795 22 10.3333 21.2538 10.3333 20.3333C10.3333 19.4129 11.0795 18.6667 12 18.6667C12.9205 18.6667 13.6667 19.4129 13.6667 20.3333Z"
      fill="currentColor"
    />
    <path
      d="M19.071 7.28592C18.4202 7.93679 17.3649 7.93679 16.714 7.28592C16.0632 6.63505 16.0632 5.57977 16.714 4.9289C17.3649 4.27802 18.4202 4.27802 19.071 4.9289C19.7219 5.57977 19.7219 6.63505 19.071 7.28592Z"
      fill="currentColor"
      fillOpacity="0.6"
    />
    <path
      d="M7.28597 19.0711C6.6351 19.722 5.57982 19.722 4.92895 19.0711C4.27808 18.4202 4.27808 17.3649 4.92895 16.7141C5.57982 16.0632 6.6351 16.0632 7.28597 16.7141C7.93685 17.3649 7.93685 18.4202 7.28597 19.0711Z"
      fill="currentColor"
      fillOpacity="0.06"
    />
    <path
      d="M16.714 19.071C16.0631 18.4202 16.0631 17.3649 16.714 16.714C17.3649 16.0632 18.4201 16.0632 19.071 16.714C19.7219 17.3649 19.7219 18.4202 19.071 19.071C18.4201 19.7219 17.3649 19.7219 16.714 19.071Z"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <path
      d="M4.9289 7.28597C4.27803 6.6351 4.27803 5.57982 4.9289 4.92895C5.57978 4.27808 6.63505 4.27808 7.28593 4.92895C7.9368 5.57982 7.9368 6.6351 7.28593 7.28597C6.63505 7.93685 5.57978 7.93685 4.9289 7.28597Z"
      fill="currentColor"
      fillOpacity="0.24"
    />
  </svg>
);

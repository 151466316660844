export const CheckmarkSquare = (className) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="1" width="18" height="18" rx="3" fill="#006CCF" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7382 6.83536C14.1297 6.45592 14.7588 6.45592 15.1503 6.83536C15.5496 7.22245 15.5496 7.85604 15.1503 8.24312L9.03916 14.1662C8.64768 14.5456 8.0186 14.5456 7.62711 14.1662L4.84934 11.4739C4.44996 11.0868 4.44996 10.4532 4.84934 10.0661C5.24082 9.68669 5.8699 9.68669 6.26139 10.0661L8.33314 12.0741L13.7382 6.83536Z"
      fill="white"
    />
    <rect
      x="1"
      y="1"
      width="18"
      height="18"
      rx="3"
      stroke="#006CCF"
      strokeWidth="2"
    />
  </svg>
);

/* eslint-disable no-restricted-globals */
import { useEffect, useReducer } from "react";
import axios from "axios";
import { initialData } from "./data.js";
import clsx from "clsx";

// functions
import { getDefaultAmount } from "./functions/getDefaultAmount.js";
import { getFormState } from "./functions/getFormState.js";
import { appdataReducer, initialAppdata } from "./functions/appdataReducer.js";
import { AppdataContext, AppdataDispatchContext } from "./functions/appdataContext.js";

// components
import Form from "./components/Form/Form";
import Infobox from "./components/Infobox/Infobox.js";

// styles
import styles from "./App.module.scss";

function App() {
  const [appdata, dispatch] = useReducer(appdataReducer, initialAppdata);
  const { initialServerData } = appdata;

  // Get initial data
  useEffect(() => {
    const reactAppData = window.ktk_donations || {};
    dispatch({
      type: "setBackendSetup",
      value: reactAppData,
    });
    const { ajax_url, nonce, post_id } = reactAppData;

    // Check for preselected amount
    const params = new URL(location.href).searchParams;
    const preSelectedAmount = params.get("amount");
    const lead_id = params.get("id");
    const session_id = params.get("session");

    let form_data = new FormData();
    form_data.set("action", "get_form_page_data");
    form_data.set("nonce", nonce);
    form_data.set("post_id", post_id);
    form_data.set("preSelectedAmount", JSON.stringify(preSelectedAmount));

    if (lead_id) {
      form_data.set("lead_id", lead_id);
    }

    if (session_id) {
      form_data.set("session_id", session_id);
    }

    axios
      .post(ajax_url, form_data)
      .then((res) => {
        if (res.data.data.lead) {
          dispatch({
            type: "setFormData",
            value: {
              ...res.data.data.lead,
              amount: res.data.data.lead.amount,
              donation_type: res.data.data.lead.donation_type,
            },
          });
        } else {
          dispatch({
            type: "setFormData",
            value: {
              ...getFormState(res.data.data.donation_type),
              amount: res.data.data.custom_amount || getDefaultAmount(res.data.data.amounts),
              customAmount: res.data.data.custom_amount,
              donation_type: res.data.data.donation_type,
            },
          });
        }
        dispatch({
          type: "setInitialServerData",
          value: res.data?.data,
        });
      })
      .catch((err) => {
        // During development only
        dispatch({
          type: "setFormData",
          value: {
            // socialsecuritynumber: "19800113-9297", // success testno
            // socialsecuritynumber: "19700123-9297", // fail testno
            ...getFormState(initialData.data.data.donation_type),
            amount: getDefaultAmount(initialData.data.data.amounts),
            donation_type: initialData.data.data.donation_type,
          },
        });
        dispatch({
          type: "setInitialServerData",
          value: initialData.data.data,
        });
      });
  }, []);

  if (!initialServerData) {
    return null;
  }

  return (
    <AppdataContext.Provider value={appdata}>
      <AppdataDispatchContext.Provider value={dispatch}>
        <div
          className={clsx({
            [styles.app]: true,
          })}
        >
          <div className={styles["content-wrapper"]}>
            {initialServerData?.infoboxes?.length > 0 && (
                  <div className={clsx(styles["infobox-group"])}>
                    {initialServerData.infoboxes.map((item, index) => (
                      <div
                        key={index}
                        className={clsx(
                          styles["infobox-wrapper"],
                          item.showOnMobile && styles["show-on-mobile"]
                        )}
                      >
                        <Infobox image={item.image} content={item.content} />
                      </div>
                    ))}
                  </div>
                )}

            <div className={styles["form-wrapper"]}>
              <Form />
            </div>
          </div>
          <div id="modal"></div>
        </div>
      </AppdataDispatchContext.Provider>
    </AppdataContext.Provider>
  );
}

export default App;

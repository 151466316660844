// functions
import { findGlobalContent } from "../../../../../functions/findGlobalContent";

// styles
import styles from "./CertificatePreview.module.scss";

// components
import Button from "../../../../Button/Button";

const CertificatePreview = ({
  isMemorialDonation,
  globalData,
  previewUrl,
  handlePreviewButton,
  ...props
}) => {
  // global content keys
  const keys = {
    buttonApprove: isMemorialDonation ? "button_approveMemorial" : "button_approveTribute",
    buttonEdit: isMemorialDonation ? "button_editMemorial" : "button_editTribute",
  };

  // default values - other
  const texts = {
    buttonApprove: isMemorialDonation ? "Godkänn minnesbladet" : "Godkänn gåvokortet",
    buttonEdit: isMemorialDonation ? "Ändra minnesbladet" : "Ändra gåvokortet",
  };

  return (
    <div className={styles["certificate-preview"]} {...props}>
      <embed
        src={`${previewUrl}#toolbar=0&navpanes=0&view=Fit&embedded=true`}
        className={styles["preview"]}
        type="application/pdf"
      />

      <div className={styles["button-container"]}>
        <Button
          onClick={() => handlePreviewButton("approve")} 
          id="approve-certificate-button"
        >
          {findGlobalContent({
            globalData,
            key: keys.buttonApprove,
            defaultValue: texts.buttonApprove,
          })}
        </Button>

        <Button
          variant="tertiary"
          onClick={() => handlePreviewButton("edit")}
          id="edit-certificate-button"
        >
          {findGlobalContent({
            globalData,
            key: keys.buttonEdit,
            defaultValue: texts.buttonEdit,
          })}
        </Button>
      </div>
    </div>
  );
};

export default CertificatePreview;

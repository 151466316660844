import clsx from "clsx";
import {
  Portal,
  Overlay,
  Content,
  Root,
  Trigger,
  Close,
} from "@radix-ui/react-dialog";

// components
import Icon from "../Icon/Icon";
import Button from "../Button/Button";

// styles
import styles from "./Modal.module.scss";

const Modal = ({
  className,
  children,
  closeModal,
  isActive,
  trigger,
  variant = "pink",
  size,
}) => (
  <Root onOpenChange={closeModal} open={isActive}>
    {trigger && <Trigger asChild>{trigger}</Trigger>}

    <Portal>
      <Overlay className={clsx(styles["overlay"])} />

      <Content
        className={clsx(
          styles["modal"],
          styles[variant],
          size && styles[size],
          className
        )}
      >
        <Close asChild>
          <Button className={styles["close"]} aria-label="Close" circular>
            <Icon variant="close" size="medium" />
          </Button>
        </Close>

        <div className={styles["modal-inner"]}>{children}</div>
      </Content>
    </Portal>
  </Root>
);

export default Modal;

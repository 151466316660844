export const inputValidation = ({ name, value }) => {
  if (name === "email" || name === "recipient_email") {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const validEmail = value.match(regex);

    if (validEmail === null) {
      return false;
    } else {
      return true;
    }
  } else if (name === "socialsecuritynumber") {
    if (value.length !== 12) {
      return false;
    } else {
      return true;
    }
  } else if (name === "clearing") {
    if (value.length !== 4) {
      return false;
    } else {
      return true;
    }
  } else if (name === "account") {
    if (value.length < 6 || value.length > 11) {
      return false;
    } else {
      return true;
    }
  } else if (name === "orgnumber") {
    if (value.length < 10) {
      return false;
    } else {
      return true;
    }
  } else if (name === "zip" || name === "recipient_zip") {
    if (value.length !== 5) {
      return false;
    } else {
      return true;
    }
  } else if (name === "burial_date") {
    const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
    
    const validDate = value.match(regex);

    if (validDate === null) {
      return false;
    } else {
      return true;
    }
  } else if (name === "phone") {
    if (value.length <= 8) {
      return false;
    } else {
      return true;
    }
  } else if (name === "amount") {
    if (value < 50) {
      return false;
    } else {
      return true;
    }
  } else if (name === "card_text") {
    if (!value) {
      return false;
    } else {
      return true;
    }
  } else {
    if (value.length < 2 || value.length > 75 ) {
      return false;
    } else {
      return true;
    }
  }
};

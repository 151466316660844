import clsx from "clsx";

// styles
import styles from "./Icon.module.scss";

// components
import { CardSymbol } from "./components/CardSymbol";
import { CheckmarkRound } from "./components/CheckmarkRound";
import { CheckmarkRoundSuccess } from "./components/CheckmarkRoundSuccess";
import { CheckmarkSquare } from "./components/CheckmarkSquare";
import { ChevronDown } from "./components/ChevronDown";
import { Close } from "./components/Close";
import { InfoTriangle } from "./components/InfoTriangle";
import { Loader } from "./components/Loader";
import { SvenskInsamlingskontroll } from "./components/SvenskInsamlingskontroll";
import { SwishSymbol } from "./components/SwishSymbol";
import { SwishSymbolAlt } from "./components/SwishSymbolAlt";
import { Calendar } from "./components/Calendar";
import { BankId } from "./components/BankId";

const Icon = ({ variant = "calendar", size, ...props }) => {
  return (
    <div
      className={clsx(
        styles["icon"],
        size && styles[size],
        props.className,
        variant === "loader" && styles["loader"]
      )}
    >
      {variant === "calendar" && <Calendar />}
      {variant === "card-symbol" && <CardSymbol />}
      {variant === "checkmark-round" && <CheckmarkRound />}
      {variant === "checkmark-round-success" && <CheckmarkRoundSuccess />}
      {variant === "checkmark-square" && <CheckmarkSquare />}
      {variant === "chevron-down" && <ChevronDown />}
      {variant === "close" && <Close />}
      {variant === "info-triangle" && <InfoTriangle />}
      {variant === "loader" && <Loader />}
      {variant === "svensk-insamlingskontroll" && <SvenskInsamlingskontroll />}
      {variant === "swish-symbol-alt" && <SwishSymbolAlt />}
      {variant === "swish-symbol" && <SwishSymbol />}
      {variant === "bank-id" && <BankId />}
    </div>
  );
};

export default Icon;

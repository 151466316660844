const appdataReducer = (appdata, action) => {
  switch (action.type) {
    case "update": {
      console.log(action);
      return appdata;
    }
    case "setInitialServerData": {
      return { ...appdata, initialServerData: action.value };
    }
    case "setFormData": {
      return { ...appdata, formData: action.value };
    }
    case "setFormValue": {
      const { name, value } = action.value;
      return { ...appdata, formData: {...appdata.formData, [name]: value }};
    }
    case "setFormErrorValue": {
      const { name, value } = action.value;
      return { ...appdata, formErrors: {...appdata.formErrors, [name]: value }};
    }
    case "setFormSuccessValue": {
      const { name, value } = action.value;
      return { ...appdata, formSuccess: {...appdata.formSuccess, [name]: value }};
    }
    case "setBackendSetup": {
      return { ...appdata, backendSetup: action.value };
    }
    case "setBillectaStatus": {
      //console.log(`Update Billecta status to: ${action.value}`);
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, requestState: action.value } };
    }
    case "setBillectaPublicID": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, publicId: action.value } };
    }
    case "setBillectaReferenceToken": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, referenceToken: action.value } };
    }
    case "setBillectaQRCode": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, qrCode: action.value } };
    }
    case "setBillectaBankIDToken": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, bankIdAutostartToken: action.value } };
    }
    case "setBillectaErrorMessage": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, errorMessage: action.value } };
    }
    case "setBillectaBankAccounts": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, bankAccounts: action.value } };
    }
    case "setBillectaRedirectURL": {
      return { ...appdata, billectaAutogiro: { ...appdata.billectaAutogiro, redirectURL: action.value } };
    }
    case "setSubmitIsLoading": {
      return { ...appdata, submitIsLoading: action.value };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
};

const initialAppdata = {
  backendSetup: {
    ajax_url: null,
    nonce: null,
    post_id: null,
  },
  formData: {
  },
  formErrors: {
  },
  formSuccess: {
  },
  billectaAutogiro: {
    bankAccounts: [],
    requestState: "idle",
    qrCode: null,
    bankIdAutostartToken: null,
    publicId: null, // used for bankaccount lookup
    referenceToken: null, // used for bankid signage
    errorMessage: null,
    redirectURL: null
  },
  submitIsLoading: false,
};

export { appdataReducer, initialAppdata };

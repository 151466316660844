import clsx from "clsx";
import { Root, Indicator } from "@radix-ui/react-checkbox";

// components
import Icon from "../Icon/Icon";

// styles
import styles from "./Checkbox.module.scss";
import { useContext } from "react";
import { AppdataContext } from "../../functions/appdataContext";

const Checkbox = ({ children, className, name, id, ...props }) => {
  const appdata = useContext(AppdataContext);
  const { formErrors } = appdata;
  const error = formErrors[name];

  return (
    <div className={clsx(styles["checkbox-outer-wrapper"])}>
      <div className={clsx(styles["checkbox-wrapper"], className)}>
        <Root
          id={id}
          name={name}
          {...props}
          className={clsx(styles["checkbox"])}
        >
          <div className={clsx(styles["indicator"])}>
            <Indicator>
              <Icon variant="checkmark-square" />
            </Indicator>
          </div>
        </Root>

        <label htmlFor={id}>{children}</label>
      </div>

      {error && <div className={styles["error-message"]}>{error}</div>}
    </div>
  );
};

export default Checkbox;

import { useContext, useEffect } from "react";
import { AppdataContext, AppdataDispatchContext } from "../functions/appdataContext";

// functions
import { inputValidation } from "../functions/inputValidation";
import { getErrorMessage } from "../functions/getErrorMessage";

export const useValidateFetchedAddressFields = (addressIsFetched) => {
  const dispatch = useContext(AppdataDispatchContext);
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;
  const fetchedKeys = ["firstname", "lastname", "address", "zip", "city"];

  useEffect(() => {
    if (addressIsFetched) {
      fetchedKeys.forEach((name) => {
        if (formData[name]) {
          const value = formData[name];
          const isValid = inputValidation({ name, value });

          if (isValid) {
            dispatch({
              type: "setFormErrorValue",
              value: { name, value: "" },
            });

            dispatch({
              type: "setFormSuccessValue",
              value: { name, value: true },
            });
          } else {
            dispatch({
              type: "setFormErrorValue",
              value: { name, value: getErrorMessage(name) },
            });

            dispatch({
              type: "setFormSuccessValue",
              value: { name, value: false },
            });
          }
        }
      });
    }
  }, [addressIsFetched]);
};

export const useValidateFetchedAccountFields = (bankAccountWasFetched) => {
  const dispatch = useContext(AppdataDispatchContext);
  const appdata = useContext(AppdataContext);
  const { formData } = appdata;
  const fetchedKeys = ["clearing", "account"];

  useEffect(() => {
    if (bankAccountWasFetched) {
      fetchedKeys.forEach((name) => {
        if (formData[name]) {
          const value = formData[name];
          const isValid = inputValidation({ name, value });

          if (isValid) {
            dispatch({
              type: "setFormErrorValue",
              value: { name, value: "" },
            });

            dispatch({
              type: "setFormSuccessValue",
              value: { name, value: true },
            });
          } else {
            dispatch({
              type: "setFormErrorValue",
              value: { name, value: getErrorMessage(name) },
            });

            dispatch({
              type: "setFormSuccessValue",
              value: { name, value: false },
            });
          }
        }
      });
    }
  }, [bankAccountWasFetched]);
};

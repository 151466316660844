export const InfoTriangle = (className) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6513 3.73355L21.9904 17.5963C23.0482 19.5943 21.5998 21.9999 19.339 21.9999H4.66085C2.40012 21.9999 0.951722 19.5943 2.00949 17.5963L9.34858 3.73354C10.4754 1.60509 13.5245 1.6051 14.6513 3.73355ZM12.8837 4.66932C12.5081 3.95984 11.4918 3.95984 11.1162 4.66932L3.77706 18.5321C3.42447 19.1981 3.90728 19.9999 4.66085 19.9999H19.339C20.0926 19.9999 20.5754 19.1981 20.2228 18.5321L12.8837 4.66932Z"
      fill="currentColor"
    />
    <path
      d="M11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V10Z"
      fill="currentColor"
    />
    <path
      d="M11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17Z"
      fill="currentColor"
    />
  </svg>
);
